import "../vendor/libphonenumber"

function initPhoneNumbers($targetForm: JQuery): void {
  const $inputPhoneNumbers = $targetForm.find(".phone-number")

  if ($inputPhoneNumbers.length) {
    $inputPhoneNumbers.each(function () {
      const $inputPhoneNumberCountryCode =
        $inputPhoneNumbers.siblings(".js-country-code")
      const $inputPhoneNumberLocalAreaCode = $inputPhoneNumbers.siblings(
        ".js-local-area-code"
      )
      const $inputPhoneNumberCallNumber =
        $inputPhoneNumbers.siblings(".js-call-number")

      if (
        $inputPhoneNumberCountryCode.val() &&
        $inputPhoneNumberLocalAreaCode.val()
      ) {
        $inputPhoneNumbers.val(
          $inputPhoneNumberCountryCode.val() +
            " " +
            $inputPhoneNumberLocalAreaCode.val() +
            " " +
            $inputPhoneNumberCallNumber.val()
        )
      } else {
        $inputPhoneNumbers.val($inputPhoneNumberCallNumber.val() || "")
      }

      $targetForm.on("submit.oskar", () => {
        const phoneNumber = $inputPhoneNumbers.val() as string
        let phoneNumberParts: string[] = []

        if (phoneNumber?.includes(" ")) {
          phoneNumberParts = phoneNumber.split(" ")

          $inputPhoneNumberCountryCode.val(phoneNumberParts[0])
          $inputPhoneNumberLocalAreaCode.val(phoneNumberParts[1])
          $inputPhoneNumberCallNumber.val(phoneNumberParts[2])
        } else {
          $inputPhoneNumberCountryCode.val("")
          $inputPhoneNumberLocalAreaCode.val("")
          $inputPhoneNumberCallNumber.val(phoneNumber)
        }
      })

      $(this).on("keyup.oskar", function () {
        // I have no idea where phoneNumberParser is coming from. Or rather,
        // where our libphonenumber is coming from, since `phoneNumberParser`
        // is not part of the official library from what I can tell.
        const internationalNumber =
          // @ts-ignore
          phoneNumberParser() /* global phoneNumberParser */

        if (internationalNumber) {
          $(this).val(internationalNumber)
        }
      })
    })
  }
}

export { initPhoneNumbers }
