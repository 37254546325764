import debounce from "lodash-es/debounce"
import Screenlock from "../common/screenlock"

const WindowHelper = {
  init(): void {
    $(window).on(
      "resize.oskar",
      debounce(function () {
        Screenlock.resize()
      }, 150)
    )

    const orientationMediaQueryList = window.matchMedia(
      "(orientation: portrait)"
    )
    orientationMediaQueryList.addListener(function () {
      Screenlock.resize()
    })
  },
}

export default WindowHelper
