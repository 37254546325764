import "bootstrap3/js/tooltip"

const Tooltips = {
  init(): void {
    if (typeof $().tooltip === "function") {
      $('[data-toggle="tooltip"]').tooltip()
    }
  },

  build($elementWithTooltip: JQuery): void {
    if (typeof $().tooltip === "function") {
      $elementWithTooltip.tooltip()
    }
  },
}

export default Tooltips
