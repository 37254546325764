import clone from "lodash-es/clone"
import Dropzone from "dropzone"
import { DropzoneFile, DropzoneOptions } from "dropzone"
import initPhotoSwipe from "./photoswipe"
import { measureDropzoneConfig } from "../measures"
import Screenlock from "./screenlock"

export type OskarFile = {
  acceptDimensions?: () => void
  rejectDimensions?: () => void
  alreadyAccepted?: boolean
  accepted: boolean
  id: string
  height: number
  name: string
  original_url: string
  response?: UploadEndpointResponse
  sha256: string
  size: number
  storage?: "store" | "cache"
  thumb_url: string
  type: string
  width: number
}

export type UploadEndpointResponse = {
  id: string
  metadata: {
    sha256: string
  }
  thumb_url: string
  storage: "store" | "cache"
}

export type OskarDropzoneConfig = {
  dropzone: Dropzone
} & Restriction

export type Restriction = {
  maxImageWidth: number
  maxImageHeight: number
  maxImageFileSizeInMegabyte: number
  maxPdfFileSizeInMegabyte?: number
}

export type OskarDropzoneFile = Dropzone.DropzoneFile & OskarFile
export const dictResolutionTooBig = (
  maxWidth: number,
  maxHeight: number
): string => `Bilder dürfen maximal ${maxWidth}x${maxHeight} Pixel groß sein.`
export const dictImageTooBig = (maxFileSizeInMegabyte: number): string =>
  `Bilder dürfen maximal ${maxFileSizeInMegabyte}MB groß sein.`
const dictPdfTooBig = (maxFileSizeInMegabyte: number): string =>
  `PDFs dürfen maximal ${maxFileSizeInMegabyte}MB groß sein.`

/**
 * Dropzones auto discover feature needs to be deactivated before the DOM is
 * loaded.
 */
Dropzone.autoDiscover = false

function isDropzoneFile(file: DropzoneFile | OskarFile): file is DropzoneFile {
  return (file as DropzoneFile).dataURL !== undefined
}

function acceptFileRestrictions(
  restrictions: Restriction
): (
  file: OskarDropzoneFile,
  done: (error?: string | Error | undefined) => void
) => void {
  const {
    maxImageWidth,
    maxImageHeight,
    maxImageFileSizeInMegabyte,
    maxPdfFileSizeInMegabyte,
  } = restrictions

  return (
    file: OskarDropzoneFile,
    done: (error?: string | Error | undefined) => void
  ): void => {
    if (maxPdfFileSizeInMegabyte && file.type.match(/application\/pdf/)) {
      if (file.size > maxPdfFileSizeInMegabyte * 1024 * 1024) {
        done(dictPdfTooBig(maxPdfFileSizeInMegabyte))
      } else {
        done()
      }
    } else if (
      file.type.match(/image/) &&
      file.size > maxImageFileSizeInMegabyte * 1024 * 1024
    ) {
      done(dictImageTooBig(maxImageFileSizeInMegabyte))
    }

    file.acceptDimensions = () => {
      file.alreadyAccepted = true
      done()
    }
    file.rejectDimensions = () => {
      done(dictResolutionTooBig(maxImageWidth, maxImageHeight))
    }
  }
}

const Dropzones = {
  /**
   * Array, where all files from an already existing Dropzone get cloned into.
   * Important if changes within the dialog get discarded via the cancel button to restore the
   * initial state of a Dropzone file list
   */
  initialFiles: [] as DropzoneFile[],

  /**
   * Array, where all Dropzones get stored.
   */
  storage: [] as Dropzone[],

  /**
   * Dropzone config
   *
   * @param clickable The element that opens the file dialog
   * @returns A Dropzone config dictionary
   */
  config(
    clickable?: HTMLElement,
    previewsContainer: string | HTMLElement = ".files"
  ): DropzoneOptions {
    return {
      clickable,
      previewsContainer,
      acceptedFiles: "image/jpeg, image/png, .jpeg, application/pdf, .pdf",
      headers: {
        // @ts-ignore
        "X-CSRF-Token": $('meta[ name="csrf-token" ]').attr("content"),
      },
      previewTemplate: $(".preview-template").html(),
      url: "/oskar/documents/upload",
      dictDefaultMessage: "Legen Sie Dateien hier ab um Sie hochzuladen.",
      dictFallbackMessage:
        "Ihr Browser unterstützt Drag&Drop Dateiuploads nicht.",
      dictFallbackText:
        "Benutzen Sie das Formular um Ihre Dateien hochzuladen.",
      dictFileTooBig:
        "Die Datei ist zu groß. Die maximale Dateigröße beträgt {{maxFileSize}}MB.",
      dictInvalidFileType:
        "Eine Datei dieses Typs kann nicht hochgeladen werden.",
      dictResponseError:
        "Der Server hat Ihre Anfrage mit Status {{statusCode}} abgelehnt.",
      dictCancelUpload: "Hochladen abbrechen",
      dictCancelUploadConfirmation:
        "Sind sie sicher, dass sie den Upload abbrechen wollen?",
      dictRemoveFile: "Datei entfernen",
      dictMaxFilesExceeded: "Sie können keine weiteren Dateien mehr hochladen.",
    }
  },

  init(config: OskarDropzoneConfig): void {
    const { dropzone, maxImageHeight, maxImageWidth } = config

    dropzone.options.accept = acceptFileRestrictions(config)

    dropzone
      .on("addedfile", (file: OskarDropzoneFile) => {
        const $preview = $(file.previewElement)
        const $filename = $preview.find(".filename")
        const $trashButton = $preview.find(".trash")
        const fileID = file.id

        if (fileID) {
          $preview.attr("data-id", fileID)
        }

        $filename.html(file.name)

        $trashButton.on("click.oskar", () => {
          dropzone.removeFile(file)
        })

        if (file.type === "application/pdf") {
          // This is a PDF, so Dropzone doesn't create a thumbnail.
          // Set a default thumbnail:
          $preview.find("svg.svg__document__pdf").show()
          $preview.find(".thumbnail").hide()
          // The proper way would be to use this API, but I'm not sure we
          // can use this with our SVG spritemap.
          // dropzone.emit("thumbnail", file, "http://path/to/image");
        }

        // Only needed for sorting, which we don't right now
        // Tooltips.init();
      })
      .on("complete", (file) => {
        const $preview = $(file.previewElement)
        const $progress = $preview.find(".progress")

        $progress.hide()
      })
      .on(
        "success",
        (file: OskarDropzoneFile, response: UploadEndpointResponse) => {
          const ID = response.id
          const $preview = $(file.previewElement)

          $preview.attr("data-id", ID)

          file.id = ID
          file.sha256 = response.metadata.sha256
          file.storage = response.storage
          file.response = response
        }
      )
      .on("thumbnail", (file: OskarDropzoneFile) => {
        const $preview = $(file.previewElement)
        const $filePreview = $preview.find(".preview")
        const $thumbnail = $preview.find(".thumbnail")
        let backgroundImage = null

        if (file.dataURL) {
          backgroundImage = file.dataURL
          // For PhotoSwipe
          // No need to set the `thumb-src`. Since we already have a `dataURL`,
          // the data is already there and we don't need a preview image.
          $filePreview.attr("data-src", file.dataURL)
        } else {
          backgroundImage = file.thumb_url
          // For PhotoSwipe
          $filePreview.attr("data-thumb-src", file.thumb_url)
          $filePreview.attr("data-src", file.original_url)
        }

        if (
          // @ts-ignore
          file.height < $thumbnail.height() && // @ts-ignore
          file.width < $thumbnail.width()
        ) {
          $thumbnail.css({ backgroundSize: "auto" })
        }

        if (file.width > maxImageWidth || file.height > maxImageHeight) {
          if (file.rejectDimensions) file.rejectDimensions()
        } else {
          // If we don't check for this, we accept a file twice which throws
          // and exception. This happens when we re-open a dialog with pre-
          // existing files.
          if (
            !file.accepted &&
            !file.alreadyAccepted &&
            file.type.match(/image/)
          ) {
            if (file.acceptDimensions) file.acceptDimensions()
          }
        }

        $thumbnail.css({ backgroundImage: "url(" + backgroundImage + ")" })
        Screenlock.scrollDistance.adjust()

        // Set data for PhotoSwipe and init
        $filePreview.attr("data-type", file.type)
        $filePreview.attr("data-name", file.name)
        $filePreview.attr("data-height", file.height)
        $filePreview.attr("data-width", file.width)
        $filePreview.attr("data-type", file.type)
        initPhotoSwipe(dropzone.element)
      })
      .on("uploadprogress", (file, progress) => {
        const $preview = $(file.previewElement)
        const $progressBar = $preview.find(".progress .bar")

        $progressBar.css({ width: progress + "%" })
      })
  },

  /**
   * Adds Dropzone to a dialog
   */
  addDropzoneToDialog($screenlock: JQuery): void {
    const $dropzone = $screenlock.find(".dropzone")
    const ID = $dropzone.data("id")
    const $serverFiles = $(`.measure-info-file-${ID}`)
    const serverFiles: OskarFile[] = []
    const $dropzoneFileContainer = $dropzone.find(".files")
    const $chooseButton = $dropzone.find(".btn.choose-files")
    const clickableElement = $chooseButton.get(0)
    let dropzone: Dropzone
    // sortable = null

    if (Dropzones.storage[ID]) {
      dropzone = Dropzones.storage[ID]
      dropzone.element = $dropzone.get(0)!
      // The stored dropzone still has an old reference to the element within
      // its listeners. To re-enable event listeners below, we need to set
      // the element correctly.
      dropzone.listeners[0].element = dropzone.element
      dropzone.previewsContainer = $dropzoneFileContainer.get(0)!
      Dropzones.initialFiles = clone(Dropzones.storage[ID].files)
      // Re-enable event listeners, e.g. drag, drag-over etc
      dropzone.enable()

      $chooseButton.on("click.oskar", (event) => {
        event.preventDefault()
        event.stopPropagation()

        // Should always exist in our case, since `hiddenFileInput` is created
        // if there are clickable elements.
        if (dropzone.hiddenFileInput) dropzone.hiddenFileInput.click()
      })
    } else {
      dropzone = new Dropzone(
        $dropzone.get(0)!,
        Dropzones.config(clickableElement)
      )

      Dropzones.init({
        dropzone,
        ...measureDropzoneConfig,
      })

      $chooseButton.on("click.oskar", (event) => {
        event.preventDefault()
        event.stopPropagation()
      })
    }

    if (Dropzones.storage[ID]) {
      Dropzones.addExistingFiles(dropzone, Dropzones.storage[ID].files)
    } else {
      if ($serverFiles.length) {
        $serverFiles.each(function () {
          // @ts-ignore
          const fileObject = JSON.parse($(this).val())
          const file: OskarFile = {
            accepted: true, // So we don't run unnecessary checks when adding existing files
            id: fileObject.id,
            height: fileObject.metadata.height,
            name: fileObject.metadata.filename,
            original_url: fileObject.original_url,
            thumb_url: fileObject.thumb_url,
            sha256: fileObject.metadata.sha256,
            size: fileObject.metadata.size,
            type: fileObject.metadata.mime_type,
            width: fileObject.metadata.width,
          }

          serverFiles.push(file)
        })

        Dropzones.addExistingFiles(dropzone, serverFiles)

        // TODO: I wonder if this is necessary. `displayExistingFile()` of
        // Dropzone 5.7 doesn't do this.
        // https://gitlab.com/meno/dropzone/-/commit/f6ea2bc84b0227897aafe635ae92baf37dc5619b
        // TODO: I don't know how to properly type this
        // @ts-ignore
        dropzone.files = serverFiles
      }
    }

    // sortable = new Sortable(
    //   $dropzoneFileContainer.get( 0 ),
    //   {
    //     draggable: '.file',
    //     ghostClass: 'sortable-ghost',
    //     handle: '.dragable'
    //   }
    // );

    Dropzones.storage[ID] = dropzone
  },

  /**
   * Add file previews of already existing files from the server or a Dropzone to the dialog.
   */
  addExistingFiles(
    dropzone: Dropzone,
    fileArray: OskarFile[] | DropzoneFile[]
  ): void {
    const fileArrayCount = fileArray.length

    for (let i = 0; i < fileArrayCount; i++) {
      const file = fileArray[i]
      dropzone.emit("addedfile", file)

      if (isDropzoneFile(file)) {
        dropzone.emit("thumbnail", file, file.dataURL)
      } else {
        dropzone.emit("thumbnail", file, file.thumb_url)
      }

      dropzone.emit("complete", file)
    }
  },
}

export default Dropzones
export { acceptFileRestrictions }
