import Cookies from "js-cookie"
import List from "list.js"
import Lists from "../common/lists"

type ModelName = "project" | "task" | "user"

function initListSorting(
  list: List,
  modelName: ModelName,
  $wrapper: JQuery
): void {
  const $listContainer = $wrapper.find(`.list-container.${modelName}s`)
  const $dropdownSort = $listContainer.find(".dropdown.sort")
  const $dropdownSortSelect = $dropdownSort.find("select")
  const urlSearchParams = new URLSearchParams(window.location.search)
  const sortOrderCookieValue = Cookies.get(`${modelName}ListSortOrder`)
  const sortOrderDropDownValue = $dropdownSortSelect.val() as string
  const sortOrderUrlParamValue = urlSearchParams.get("sortBy")
  const sortBy =
    sortOrderUrlParamValue || sortOrderCookieValue || sortOrderDropDownValue

  $dropdownSortSelect.on("change.oskar", function () {
    const selectValue = $(this).val() as string

    setQueryStringParameter("sortBy", selectValue)
    Cookies.set(`${modelName}ListSortOrder`, selectValue)

    sortListBy(list, selectValue)
  })

  sortListBy(list, sortBy)
  setQueryStringParameter("sortBy", sortBy)
  $dropdownSortSelect.val(sortBy).trigger("change.select2")
}

// Taken from https://stackoverflow.com/a/41505583/1928168,
// https://github.com/turbolinks/turbolinks/issues/549#issuecomment-642084855,
// https://github.com/turbolinks/turbolinks/blob/v5.2.0/src/turbolinks/controller.coffee#L75.
function setQueryStringParameter(name: string, value: string) {
  const params = new URLSearchParams(window.location.search)

  params.set(name, value)

  // These Turbolinks methods are undocumented. However, using the history API
  // directly does not work as we'd skip setting Turbolinks internal state
  // correctly. So we have to use this function so that Turbolinks stays in
  // sync.
  // @ts-ignore
  window.Turbolinks.controller.replaceHistoryWithLocationAndRestorationIdentifier(
    `${window.location.pathname}?${params}`,
    // @ts-ignore
    window.Turbolinks.uuid()
  )
}

/**
 * Sort list by the selected value whenever the selection changes. Names are
 * sorted in ascending alphabetical order (A-Z), activity in descending order
 * (newest on top).
 */
function sortListBy(list: List, sortBy: string) {
  switch (sortBy) {
    case "first_name": // User
    case "last_name": // User
    case "name": // Project, Task
      Lists.sort(list, sortBy, "asc")
      break
    case "activity": // Project, Task, User
      Lists.sort(list, sortBy, "desc")
      break
  }
}

export { initListSorting }
