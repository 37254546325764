import Lists from "./common/lists"

function initShow($wrapper: JQuery): void {
  const $sectionListContainer = $wrapper.find(".list-container.sections")
  const $sections = $sectionListContainer.find("li")
  const $sectionList = $sectionListContainer.find(".list.sections")
  const $sectionListItems = $sectionList.find("li")
  const $messagesContainer = $wrapper.find(".chat-container .messages")

  Lists.layoutAndInit($sectionListContainer)

  $sectionListItems.on("click.oskar", function () {
    const $sectionId = $(this).data("section-id")

    $messagesContainer.attr("data-section-id", $sectionId)
    $sections.removeClass("selected")
    $(this).addClass("selected")
  })

  // This does not work as intended. Triggering a `click` on the anchor element
  // does not actually trigger clicking the link. Apparently we can either use
  // native DOM APIs or click on the content instead.
  // See: https://stackoverflow.com/questions/5811122/how-to-trigger-a-click-on-a-link-using-jquery
  //
  // if ($sectionListItems.length === 1) {
  //   $sectionListItems.find("a").trigger("click")
  // }
}

const Documentations = {
  init($wrapper: JQuery): void {
    if ($wrapper.hasClass("show")) {
      initShow($wrapper)
    }
  },
}

export default Documentations
