import { ReactNode, Component, RefObject, createRef } from "react"
import options from "./options"
import { Node } from "prosemirror-model"
import { EditorState, Transaction } from "prosemirror-state"
import { EditorView, EditorProps as EditorViewProps } from "prosemirror-view"
import "prosemirror-view/style/prosemirror.css"

interface EditorProps extends EditorViewProps {
  initialValue: Node
  onChange?(state: EditorState): void
  autoFocus?: boolean
  render?({
    editor,
    view,
  }: {
    editor: Editor | JSX.Element
    view: EditorView
  }): ReactNode
  canEdit: boolean
}

class Editor extends Component<EditorProps> {
  editorRef: RefObject<HTMLDivElement>

  view: EditorView

  constructor(props: EditorProps) {
    super(props)

    this.editorRef = createRef()
    const oskarOptions = Object.assign(options, { doc: props.initialValue })

    this.view = new EditorView(null, {
      ...this.props,
      state: EditorState.create(oskarOptions),
      dispatchTransaction: (transaction: Transaction) => {
        const { state, transactions } =
          this.view.state.applyTransaction(transaction)
        this.view.updateState(state)
        if (transactions.some((tr: Transaction) => tr.docChanged)) {
          if (this.props.onChange !== undefined) this.props.onChange(state)
        }
        this.forceUpdate()
      },
      // With TypeScript 4.4. we are using `exactOptionalPropertyTypes`. For
      // this, `| undefined` was added in a PR and later removed. So in this
      // case, the types are wrong, not our code.
      // https://github.com/DefinitelyTyped/DefinitelyTyped/pull/54346/files#diff-45cce90cfdee25da2b6dc0665f0a09dc7f98469c97e47defb780342c821b94d2
      // https://github.com/DefinitelyTyped/DefinitelyTyped/pull/54668/files
      // PR to change this: https://github.com/DefinitelyTyped/DefinitelyTyped/pull/55483
      // @ts-ignore
      // attributes: this.props.attributes,
      // @ts-ignore
      // nodeViews: this.props.nodeViews,
      editable: () => this.props.canEdit,
    })
  }

  componentDidMount(): void {
    this.editorRef.current?.appendChild(this.view.dom)
    if (this.props.autoFocus) {
      this.view.focus()
    }
  }

  render(): ReactNode {
    const editor = <div ref={this.editorRef} />
    return this.props.render
      ? this.props.render({
          editor,
          view: this.view,
        })
      : editor
  }
}

export default Editor
