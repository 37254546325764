import Cookies from "./common/cookies"
import debounce from "lodash-es/debounce"
import BottomBar from "./common/bottombar"
import Checkboxes from "./common/checkboxes"
import Forms from "./common/forms"
import List from "list.js"
import Lists, {
  FilterType,
  FilterValue,
  ObjectType,
  EventType,
} from "./common/lists"
import RelativeTime from "./common/relative_time"
import Selects from "./common/selects"
import Tooltips from "./common/tooltips"
import sprite from "../images/sprite.svg"
import { initListSorting } from "./helpers/sortOrderHelper"

function isNewPersonSelect(
  $select: JQuery<HTMLSelectElement>,
  $peopleTableBody: JQuery<HTMLTableSectionElement>
) {
  return $select.parents("tr").is($peopleTableBody.find(".table-row").last())
}

function onRemoveButtonClick($targetButton: JQuery) {
  const $parentTableRow = $targetButton.closest("tr")
  const $option = $parentTableRow
    .find("td.person")
    .find("select")
    .find("option")
    .filter(":selected")

  // Set `_destroy` to true
  $targetButton.prev().val("true")
  $targetButton.closest("tr").hide()

  if ($option.length) {
    Selects.linked.unselectLinkedOption("person", $option)
    Selects.updateOptions("person")
  }

  BottomBar.show()
}

function onNewPersonSelect(
  $peopleTable: JQuery<HTMLTableElement>,
  $peopleTableBody: JQuery<HTMLTableSectionElement>,
  $newPersonButton: JQuery,
  $form: JQuery<HTMLFormElement>
) {
  const $peopleTableBodyLastRow = $peopleTableBody.find(".table-row").last()
  const $personTableCell = $peopleTableBodyLastRow.find(".person").first()
  const $projectManagementTableCell = $peopleTableBodyLastRow
    .find(".project-management")
    .first()
  const $personSelect = $personTableCell.find(".person.search.select.input")
  const $regularOption = $peopleTableBodyLastRow.find(
    '.role option[value="REGULAR"]'
  )
  const html = $newPersonButton.data("fields")
  const id = $newPersonButton.data("id")
  const organizationName = $personTableCell
    .find("select")
    .find(":selected")
    .data("org")
  const hasRightsToAdministrate = $personTableCell
    .find("select")
    .find(":selected")
    .data("hasrightstoadministrate") // jQuery converts has-rights-to-administrate to this.
  const selectedPersonName = $personTableCell
    .find(".select2-selection__rendered")
    .html()

  /*
   * On the Rails side we could have done something like `selected:
   * f.object.role || :REGULAR`, but that would fill out part of the form data,
   * which would result in a non-empty payload. On non-empty payloads the
   * validations on the server side would be triggered, thus resulting in errors
   * on form submit. That's because we do `reject_if: :all_blank`, and if we'd
   * set a default value, it wouldn't be blank.
   *
   * tl;dr: We set this in JavaScript after a new person was selected.
   */
  $regularOption.attr("selected", "selected")
  $regularOption.trigger("change.select2")

  /*
   * Display username and organization name in the same kind of divs that
   * are used in the user list and hide the select. Also set a different
   * background color for those unsaved list additions.
   */
  // TODO: The jQuery definitions seem correct, at least in the online
  // playground as well as their babel starter project this doesn't raise type
  // errors.
  $personTableCell.append(
    // @ts-ignore
    $("<div>")
      .addClass("user-data") // @ts-ignore
      .append($("<div>").addClass("user-name").append(selectedPersonName))
      .append(
        // @ts-ignore
        $("<div>").addClass("organization-name").append(organizationName)
      )
  )

  if (hasRightsToAdministrate) {
    const $lockedCheckbox = $("<div>")
      .addClass("locked-checkbox")
      .attr(
        "data-original-title",
        "Admins und Manager können Projekte immer verwalten."
      )
      .append(
        `<svg role="img" class="svg__checkbox__locked"><use class="lock" xlink:href="${sprite}#checkbox__locked"/></svg>`
      )

    $projectManagementTableCell.empty()
    // @ts-ignore
    $projectManagementTableCell.append($lockedCheckbox)
    Tooltips.build($lockedCheckbox)
  }

  $personSelect.hide()
  $peopleTableBodyLastRow.css("background-color", "#F7F4EE")
  addNewTableRow(
    id,
    html,
    $peopleTable,
    $peopleTableBody,
    $newPersonButton,
    $form
  )
}

function addNewTableRow(
  id: string,
  html: string,
  $peopleTable: JQuery<HTMLTableElement>,
  $peopleTableBody: JQuery<HTMLTableSectionElement>,
  $newPersonButton: JQuery,
  $form: JQuery<HTMLFormElement>
) {
  const time = new Date().getTime()
  const regexp = new RegExp(id, "g")
  $peopleTable.append(html.replace(regexp, time.toString()))

  const $peopleTableBodyLastRow = $peopleTableBody.find(".table-row").last()
  const $checkboxes = $peopleTableBodyLastRow.find(".checkbox")
  const $removeButton = $peopleTableBodyLastRow.find(".btn.remove")
  const $selects = $peopleTableBodyLastRow.find("select")
  const $newPersonInput = $peopleTableBodyLastRow.find(".input").first()
  const $newPersonSelect = $selects.first()

  $checkboxes.each(function () {
    Checkboxes.build($(this))
  })

  $selects.each(function () {
    Selects.build($(this))
  })

  $newPersonInput.removeClass("disabled")

  $newPersonSelect
    .removeClass("disabled")
    .prop("disabled", false)
    .on("select2:select", function (this: HTMLSelectElement) {
      if (isNewPersonSelect($(this), $peopleTableBody)) {
        onNewPersonSelect(
          $peopleTable,
          $peopleTableBody,
          $newPersonButton,
          $form
        )
      }
    })

  $removeButton.on("click.oskar", function (event) {
    event.preventDefault()
    event.stopPropagation()

    onRemoveButtonClick($(this))
  })

  Forms.listenForChanges($form)
}

const Projects = {
  new: {
    init($wrapper: JQuery): void {
      const $peopleTableBodyLastRow = $wrapper
        .find(".people table tbody .table-row")
        .last()
      const $newPersonSelect = $peopleTableBodyLastRow.find("select").first()
      const currentUserId = $newPersonSelect.find("[data-itsme]").val()

      // Add current user to project automatically
      if (currentUserId) {
        $newPersonSelect.val(currentUserId)
        $newPersonSelect.trigger("change.select2")
        $newPersonSelect.trigger("select2:select")
      }
    },
  },

  edit: {
    init($wrapper: JQuery): void {
      const $people = $wrapper.find(".people")
      const $form = $people.parents("form")
      const $newPersonButton = $people.find(".btn.new")
      const $peopleTable = $people.find("table")
      const $peopleTableBody = $peopleTable.find("tbody")
      const $peopleTableBodyRemoveButtons = $peopleTableBody.find(".btn.remove")
      const $relativeTimeFields = $wrapper.find(".js-relative-time")
      const $peopleTableBodyLastRow = $peopleTableBody.find(".table-row").last()
      const $newPersonInput = $peopleTableBodyLastRow.find(".input").first()
      const $newPersonSelect = $peopleTableBodyLastRow.find("select").first()
      const $newPersonSelectValue = $newPersonSelect.val()

      if ($newPersonSelectValue === "") {
        $newPersonInput.removeClass("disabled")

        $newPersonSelect.removeClass("disabled").prop("disabled", false)

        $newPersonSelect.on("select2:select", function () {
          if (isNewPersonSelect($(this), $peopleTableBody)) {
            onNewPersonSelect(
              $peopleTable,
              $peopleTableBody,
              $newPersonButton,
              $form
            )
          }
        })
      } else {
        const functionInput = $peopleTableBodyLastRow
          .find("td.function")
          .find("input")
          .attr("readonly")
        if (
          isNewPersonSelect($newPersonSelect, $peopleTableBody) &&
          functionInput !== "readonly"
        ) {
          onNewPersonSelect(
            $peopleTable,
            $peopleTableBody,
            $newPersonButton,
            $form
          )
        }
      }

      $peopleTableBodyRemoveButtons.on("click.oskar", function (event) {
        event.preventDefault()
        event.stopPropagation()

        onRemoveButtonClick($(this))
      })

      $newPersonButton.on("click.oskar", (event) => {
        event.preventDefault()
        event.stopPropagation()

        $newPersonSelect.select2("open")
      })

      // The select field in the last row was disabled at initial page load and
      // got enabled in this module. So, the change listener of the
      // corresponding form must get reinitialized.
      Forms.listenForChanges($form)

      if ($relativeTimeFields.length) {
        RelativeTime.setRelativeTimes($relativeTimeFields)
      }
    },
  },

  index: {
    init($wrapper: JQuery): void {
      const $projectListContainer = $wrapper.find(".list-container.projects")
      const $search = $projectListContainer.find(".search").find("input")
      const $dropdownState = $projectListContainer.find(".dropdown.state")
      const $dropdownStateSelect = $dropdownState.find("select")
      const $projectList = $projectListContainer.find(".list.projects")
      const $projectListItems = $projectList.find("li[data-project-id]")
      const projectListObject = new List($projectListContainer.get(0)!, {
        page: 1000,
        searchClass: "search-trigger",
        sortClass: "sort-trigger",
        valueNames: ["activity", "archived", "name"],
        fuzzySearch: {
          // A value of 150 helps finding projects with long names that have
          // the relevant search term at the end. Default is 100. 150 was
          // determined by trial and error. See documentation here:
          // https://listjs.com/docs/fuzzysearch/
          distance: 150,
        },
      })
      const filterCookieValue = Cookies.get("listProjectsFilterState")
      const permissions = JSON.parse($("#permissions").html() || "{}")

      if (!$projectListContainer.length) {
        return
      }

      const filterList = (filter: FilterType, value: FilterValue) => {
        Lists.filter($projectListContainer, projectListObject, filter, value)
        Lists.layoutAndInit($projectListContainer)
      }

      initListSorting(projectListObject, "project", $wrapper)

      if (filterCookieValue) {
        $dropdownStateSelect.val(filterCookieValue).trigger("change")
      }

      filterList(FilterType.ARCHIVED, $dropdownStateSelect.val() as FilterValue)

      $search.on(
        "change.oskar keyup.oskar",
        debounce(function (this: JQuery<HTMLInputElement>) {
          Lists.search(projectListObject, $(this).val() as string)
        }, 150)
      )

      // Filter project list by the selected state
      $dropdownStateSelect.on("change.oskar", function () {
        filterList(FilterType.ARCHIVED, $(this).val() as FilterValue)
      })

      // Hide archive/unarchive buttons if the current user doesn't have persmissions to use them.
      $projectListItems.each(function () {
        const currentProjectId = $(this).data("project-id")
        const $quickActionsColumn = $(this).find(".column.quickactions")
        const archiveOrUnarchive = $quickActionsColumn.data("is-archived")
          ? "unarchive"
          : "archive"
        const userCanManage = permissions[currentProjectId]["can_manage"]
        const $archiveButton = $quickActionsColumn.find(
          `.btn.icon-only.${archiveOrUnarchive}`
        )
        const $editButton = $quickActionsColumn.find(".btn.icon-only.edit")
        const $viewButton = $quickActionsColumn.find(".btn.icon-only.view")

        if (userCanManage) {
          $viewButton.hide()
        } else {
          $editButton.hide()
          $archiveButton.hide()
        }
      })

      Lists.checkIfListIsEmpty(
        $projectListContainer,
        projectListObject,
        ObjectType.PROJECTS,
        EventType.INITIAL
      )

      projectListObject.on("filterComplete", () => {
        Lists.checkIfListIsEmpty(
          $projectListContainer,
          projectListObject,
          ObjectType.PROJECTS,
          EventType.FILTER_COMPLETE
        )
      })

      projectListObject.on("searchComplete", () => {
        Lists.checkIfListIsEmpty(
          $projectListContainer,
          projectListObject,
          ObjectType.PROJECTS,
          EventType.SEARCH_COMPLETE
        )
      })

      projectListObject.on("sortComplete", () => {
        Lists.checkIfListIsEmpty(
          $projectListContainer,
          projectListObject,
          ObjectType.PROJECTS,
          EventType.SORT_COMPLETE
        )
      })
    },
  },

  invite: {
    init($wrapper: JQuery): void {
      const $invitations = $wrapper.find(".invitations")
      const $form = $invitations.find("form")
      const $userIdInput = $form.find("input#project_invitees_username")
      const $submitButton = $form.find(".btn")
      const $error = $form.find(".error")

      $userIdInput.on("input", function (this: HTMLInputElement) {
        if (this.checkValidity()) {
          $error.text("")
          $submitButton.prop("disabled", this.value === "")
        } else {
          $error.text("Keine gültige Benutzer-ID (z.B. 12345678)")
          $submitButton.prop("disabled", true)
        }
      })
    },
  },

  init($wrapper: JQuery): void {
    if (
      $wrapper.hasClass("create") ||
      $wrapper.hasClass("edit") ||
      $wrapper.hasClass("new") ||
      $wrapper.hasClass("update")
    ) {
      Projects.edit.init($wrapper)
    }

    if ($wrapper.hasClass("new")) {
      Projects.new.init($wrapper)
    }

    if ($wrapper.hasClass("edit")) {
      Projects.invite.init($wrapper)
    }

    if ($wrapper.hasClass("index") || $wrapper.hasClass("show")) {
      Projects.index.init($wrapper)
    }
  },
}

export default Projects
