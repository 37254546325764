export default {
  doc: {
    type: "doc",
    content: [{ type: "paragraph" }],
  },
  selection: {
    type: "text",
    anchor: 1,
    head: 1,
  },
}
