import isEmpty from "lodash-es/isEmpty"
import sprite from "../../images/sprite.svg"
import Messages from "./messages"
import initPhotoSwipe from "./photoswipe"
import Screenlock from "./screenlock"
import { Mode } from "./screenlock"

type Action = {
  classes?: string
  goal: Goal
  order: "primary" | "secondary"
  string: string
}

type Goal =
  | {
      type: "link" | "none" | null
      url?: string
    }
  | {
      method: <T = unknown>(args?: T) => void
      parameters?: null
      type: "method"
    }

export type Option = {
  actions: Action[]
  ID?: number
  text?: string
  title: string
  type?: Type
}

enum Size {
  large = "large",
  small = "small",
}

enum Type {
  info = "info",
  question = "question",
}

const Dialogs = {
  large: {
    show(options: Option): void {
      Dialogs.build(Mode.locked, Size.large, options)
    },
  },

  small: {
    info: {
      show(options: Option): void {
        options.type = Type.info

        Dialogs.build(Mode.locked, Size.small, options)
      },
    },

    question: {
      answer(options: Option): void {
        options.type = Type.question

        return Dialogs.build(Mode.locked, Size.small, options)
      },
    },
  },

  build(mode: Mode, size: Size, options: Option): void {
    const $screenlock = $("#screenlock")
    const actions = options.actions
    const actionsCount = actions.length
    let dialogHTML = ""
    let actionsHTML = ""

    if (size === Size.small) {
      /**
       * Build HTML code
       */
      ;(function () {
        const type = options.type
        const title = options.title
        const text = options.text
        let dialogSvgIcon = ""

        if (type === Type.info) {
          dialogSvgIcon = `<svg role="img" class="svg__carret_down"><use class="carret_down" xlink:href="${sprite}#dialog_info"/></svg>`
        } else if (type === Type.question) {
          dialogSvgIcon = `<svg role="img" class="svg__carret_down"><use class="carret_down" xlink:href="${sprite}#dialog_question"/></svg>`
        }

        dialogHTML =
          '<div id="dialog-wrapper">' +
          `<div id="dialog" class="${size} ${type}">` +
          '<div class="header">' +
          dialogSvgIcon +
          "</div>" +
          '<div class="body">' +
          `<h2>${title}</h2>` +
          `<p>${text}</p>` +
          "</div>" +
          '<div class="footer"></div>' +
          "</div>" +
          '<div class="placeholder"></div>' +
          "</div>"
      })()
    } else {
      /**
       * Load the right partial and build HTML code
       */
      ;(function () {
        let $dialogPartial = null
        let dialogPartialContent = null

        if ($(".js-dialog-measure-" + options.ID).length) {
          $dialogPartial = $(".js-dialog-measure-" + options.ID)
        } else {
          $dialogPartial = $(".js-dialog")
        }

        if ($dialogPartial.length) {
          /**
           * Clone (deep copy) the dialog partial code and fill in the missing item specific data
           */
          ;(function () {
            const $dialogClone = $dialogPartial.clone()
            const $dropzone = $dialogClone.find(".dropzone")
            const ID = options.ID
            const title = options.title

            $dialogClone.find("h2").html(title)

            $dropzone.attr({
              id: "dropzone-" + ID,
              "data-id": ID,
            })

            dialogPartialContent = $dialogClone.html()
          })()
        }

        dialogHTML =
          '<div id="dialog-wrapper">' +
          `<div id="dialog" class="${size} show">` +
          dialogPartialContent +
          "</div>" +
          '<div class="placeholder"></div>' +
          "</div>"
      })()
    }

    /**
     * Insert the HTML code into the screenlock
     */
    $screenlock.html(dialogHTML)

    /**
     * Insert the actions into the dialog
     */
    if (!isEmpty(actions)) {
      for (let i = 0; i < actionsCount; i++) {
        actionsHTML +=
          `<button class="btn ${actions[i]["order"]} ${actions[i]["classes"]}">` +
          actions[i]["string"] +
          "</button>"
      }

      $screenlock.find("#dialog > .footer").html(actionsHTML)
    }

    /**
     * Add the actions of the dialog buttons
     */
    ;(function () {
      const $dialog = $screenlock.find("#dialog")
      const $dialogFooter = $dialog.find(".footer")
      let $currentAction = null

      if (!isEmpty(actions)) {
        for (let i = 0; i < actionsCount; i++) {
          $currentAction = $(".btn." + actions[i]["order"], $dialogFooter)
          ;(function () {
            const currentActionObject = actions[i]

            $currentAction.on("click.oskar", () => {
              switch (currentActionObject.goal.type) {
                case "link":
                  // @ts-ignore
                  window.Turbolinks.visit(currentActionObject.goal.url)
                  break
                case "method":
                  currentActionObject.goal.method(
                    currentActionObject.goal.parameters
                  )
                  Screenlock.hide()
                  break
                default:
                  Screenlock.hide()
              }
            })
          })()
        }
      }
    })()

    Screenlock.show(mode)

    /**
     * Prepare measure base for display
     */
    ;(() => {
      const $imagePreviews = $screenlock.find(
        '.files .preview[data-type^="image"]'
      )
      const $pdfPreviews = $screenlock.find(
        '.files .preview[data-type="application/pdf"]'
      )

      $imagePreviews.each(function () {
        const $image = $(this).find(".thumbnail")
        const thumbnailSrc = $(this).data("thumb-src")
        $image.css({ backgroundImage: "url(" + thumbnailSrc + ")" })
      })

      $pdfPreviews.each(function () {
        const $preview = $(this)
        const $pdf = $preview.find(".thumbnail")

        $preview.find("svg.svg__document__pdf").show()
        $pdf.hide()
      })
    })()

    initPhotoSwipe($screenlock)
    initLinks($screenlock)
  },
}

function initLinks($screenlock: JQuery) {
  $screenlock.find(".copy-link").each((_, anchor) => {
    const $anchor = $(anchor)

    // If we'd use our own tooltips helper here, the tooltip wouldn't go away
    // after clicking the anchor element because the element would be focused.
    // By only listening to `hover`, we get the desired behavior.
    $anchor.tooltip({ trigger: "hover" })

    $anchor.on("click", (event) => {
      event.preventDefault()
      const link = $(event.target).attr("href")
      if (link) copyTextToClipboard(link)
    })
  })
}

function copyTextToClipboard(text: string) {
  navigator.clipboard.writeText(text).then(
    function () {
      $("#message").remove()
      Messages.setMessage("success", "Link in die Zwischenablage kopiert.")
      // This is a hack. The message container has a z-index of 2010, while the
      // screenlock as one of 2030. I assume this was carefully chosen and so I
      // only want to temporarily want to override it.
      $("#message").css("z-index", "2040")
    },
    function () {
      $("#message").remove()
      Messages.setMessage(
        "alert",
        "Link konnte nicht in die Zwischenablage kopiert werden."
      )
      // This is a hack. The message container has a z-index of 2010, while the
      // screenlock as one of 2030. I assume this was carefully chosen and so I
      // only want to temporarily want to override it.
      $("#message").css("z-index", "2040")
    }
  )
}

export default Dialogs
