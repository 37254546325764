import Cookies from "../common/cookies"

type BeforeVisitEvent = Event & { data: { url: string } }

const FilterHelper = {
  init(): void {
    document.addEventListener("turbolinks:before-visit", (event) => {
      const currentURL = (event.currentTarget as Document).URL
      // Some casts are necessary:
      // https://github.com/microsoft/TypeScript/issues/28357
      const destinationURL = (event as BeforeVisitEvent).data.url

      // Clear filter cookies of the lists
      if (destinationURL !== currentURL) {
        Cookies.remove("listProjectsFilterState")
        Cookies.remove("listTasksFilterState")
      }
    })
  },
}

export default FilterHelper
