const Buttons = {
  save: {
    init(): void {
      const $saveButtons = $(".btn.save")

      const isRemote = function isRemote(element: JQuery) {
        return (
          element.data("remote") !== undefined &&
          element.data("remote") !== false
        )
      }

      $saveButtons.on("click.oskar", function () {
        const $bottomBar = $(this).parents(".bottombar")
        const model = $bottomBar.data("model")
        const page = $bottomBar.data("page")
        const $targetForm = $(
          `form[data-model="${model}"][data-page="${page}"]`
        )
        const remote = isRemote($targetForm)

        if (!remote) {
          $targetForm.submit()
        }
      })
    },
  },

  init(): void {
    Buttons.save.init()
  },
}

export default Buttons
