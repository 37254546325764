import { history } from "prosemirror-history"
import { gapCursor } from "prosemirror-gapcursor"

import "prosemirror-gapcursor/style/gapcursor.css"

import keys from "./keys"
import placeholder from "./plugins/placeholder"
import replaceWordBulletLists from "./plugins/replaceWordBulletLists"
import replaceWordOrderedLists from "./plugins/replaceWordOrderedLists"
import sanitizeWordPastedHtml from "./plugins/sanitizeWordPastedHtml"
import rules from "./rules"

export default [
  rules,
  keys,
  placeholder(),
  // Order matters! Sanitize before replacing.
  sanitizeWordPastedHtml(),
  replaceWordBulletLists(),
  replaceWordOrderedLists(),
  gapCursor(),
  history(),
]
