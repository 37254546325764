const navigation = {
  init() {
    const $header = $("#header")
    const $dropdowns = $header.find(".dropdown")

    $dropdowns.each(function () {
      $(this).on("click.oskar", function () {
        const $toggle = $(this).find(".toggle")
        const $submenu = $(this).find(".submenu")

        if ($toggle.hasClass("open")) {
          $toggle.removeClass("open")
        } else {
          $toggle.addClass("open")
        }

        $submenu.toggle()
      })
    })
  },
}

$(document).ready(() => {
  navigation.init()
})

export {}
