import PhotoSwipe from "photoswipe"
import PhotoSwipeUI_Default from "photoswipe/dist/photoswipe-ui-default"
import "photoswipe/dist/photoswipe.css"
import "photoswipe/dist/default-skin/default-skin.css"

export const OPTIONS = {
  bgOpacity: 0.8,
  closeOnScroll: false,
  errorMsg:
    '<div class="pswp__error-msg">Das Bild konnte nicht geladen werden.</div>',
  history: false,
  index: 0,
  shareEl: false,
  showHideOpacity: true,
}

function initPhotoSwipeForItemsAtIndex(
  photoSwipeItems: PhotoSwipeUI_Default.Item[],
  index: number,
  element: HTMLElement | HTMLAnchorElement
) {
  $(element)
    .off("click.oskar")
    .on("click.oskar", (event) => {
      event.preventDefault()
      event.stopPropagation()

      const $photoswipeOverlay = $(".pswp")[0]
      const options = OPTIONS
      options.index = index

      const photoswipe = new PhotoSwipe(
        $photoswipeOverlay,
        PhotoSwipeUI_Default,
        photoSwipeItems,
        options
      )
      photoswipe.init()
    })
}

function generatePhotoSwipeItemsFromElements(
  $elements: JQuery | JQuery<HTMLImageElement>
): PhotoSwipeUI_Default.Item[] {
  return $elements
    .map(function (_index: number, element: HTMLElement | HTMLImageElement) {
      const $element = $(element)
      return {
        msrc: $element.data("thumb-src"),
        src: $element.data("src"),
        w: $element.data("width"),
        h: $element.data("height"),
        title: $element.data("name"),
      }
    })
    .get()
}

function setImagePreviews(
  $imagePreviews: JQuery,
  photoSwipeItems: PhotoSwipeUI_Default.Item[]
) {
  $imagePreviews.each((index, element) => {
    initPhotoSwipeForItemsAtIndex(photoSwipeItems, index, element)
  })
}

function setPdfPreviews($pdfPreviews: JQuery) {
  $pdfPreviews.each(function () {
    $(this)
      .off("click.oskar")
      .on("click.oskar", function (event) {
        event.preventDefault()
        event.stopPropagation()

        window.open($(this).data("src"))
      })
  })
}

function initPhotoSwipeForChat() {
  const $dynamicContentMessages = $(".dynamic .message")

  $dynamicContentMessages.each(function () {
    const $links = $(this).find("a")
    const $images = $links.find("img")
    const photoSwipeItems = generatePhotoSwipeItemsFromElements($images)

    $links.each((index, element) => {
      initPhotoSwipeForItemsAtIndex(photoSwipeItems, index, element)
    })
  })
}

function initPhotoSwipe(container: JQuery | HTMLElement): void {
  const $container = $(container)
  const $imagePreviews = $container.find('.files .preview[data-type^="image"]')
  const $pdfPreviews = $container.find(
    '.files .preview[data-type="application/pdf"]'
  )
  const photoSwipeItems = generatePhotoSwipeItemsFromElements($imagePreviews)

  setImagePreviews($imagePreviews, photoSwipeItems)
  setPdfPreviews($pdfPreviews)
}

// "Export" for use in .js.erb files
// @ts-ignore
window.PhotoSwipe = {}
// @ts-ignore
window.PhotoSwipe.initPhotoSwipeForChat = initPhotoSwipeForChat

export default initPhotoSwipe
