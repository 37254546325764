const PanelGroups = {
  init(): void {
    const $panelGroups = $(".panel-group")

    if ($panelGroups.length) {
      const $panelBodies = $panelGroups.find(".panel-body")
      const $prefixes = $panelBodies.find("span")

      $prefixes.each(function () {
        const content = $(this).html()

        if (content.substr(content.indexOf(".")).length - 1 > 1) {
          $(this).css({ marginRight: "15px" })
        }
      })
    }
  },
}

export default PanelGroups
