import debounce from "lodash-es/debounce"
import Forms from "./common/forms"
import List from "list.js"
import Lists from "./common/lists"
import { EventType, ObjectType } from "./common/lists"
import RelativeTime from "./common/relative_time"
import { initPhoneNumbers } from "./common/phone_number_utils"
import { initListSorting } from "./helpers/sortOrderHelper"

function addRequiredFieldLogic(
  $targetField: JQuery,
  $passwordField: JQuery,
  $passwordConfirmField: JQuery
) {
  $targetField.on("focusin.oskar keyup.oskar", function () {
    if (
      !$(this).hasClass("required") &&
      ($passwordField.val() !== "" || $passwordConfirmField.val() !== "")
    ) {
      Forms.requirements.setRequiredField($passwordField)
      Forms.requirements.setRequiredField($passwordConfirmField)
    }
  })

  $targetField.on("change.oskar focusout.oskar keyup.oskar", function () {
    if (
      $(this).hasClass("required") &&
      $passwordField.val() === "" &&
      $passwordConfirmField.val() === ""
    ) {
      Forms.requirements.unsetRequiredField($passwordField)
      Forms.requirements.unsetRequiredField($passwordConfirmField)
    }
  })
}

function disableSorterForOrganizationUserRoleSelect2($wrapper: JQuery) {
  const organizationUserRoleSelect2Options = $wrapper
    .find("#user_organization_relationship_attributes_role")
    .data("select2").options.options

  organizationUserRoleSelect2Options.sorter = (data) => {
    return data
  }
}

const Users = {
  edit: {
    init($wrapper: JQuery): void {
      const $passwordField = $("#user_password")
      const $passwordConfirmField = $("#user_password_confirmation")
      const $relativeTimeFields = $wrapper.find(".js-relative-time")

      addRequiredFieldLogic(
        $passwordField,
        $passwordField,
        $passwordConfirmField
      )
      addRequiredFieldLogic(
        $passwordConfirmField,
        $passwordField,
        $passwordConfirmField
      )

      if ($relativeTimeFields.length) {
        RelativeTime.setRelativeTimes($relativeTimeFields)
      }

      initPhoneNumbers($("form").not("[data-no-global-validation]"))

      // For this select, we define our own sort order in the backend.
      disableSorterForOrganizationUserRoleSelect2($wrapper)
    },
  },

  index: {
    init($wrapper: JQuery): void {
      const $userListContainer = $wrapper.find(".list-container.users")
      const $search = $userListContainer.find(".search").find("input")
      const usersListObject = new List($userListContainer.get(0)!, {
        searchClass: "search-trigger",
        sortClass: "sort-trigger",
        valueNames: ["activity", "first_name", "last_name"],
      })

      initListSorting(usersListObject, "user", $wrapper)
      Lists.layoutAndInit($userListContainer)

      $search.on(
        "change.oskar keyup.oskar",
        debounce(function (this: JQuery<HTMLInputElement>) {
          Lists.search(usersListObject, $(this).val() as string)
        }, 150)
      )

      Lists.checkIfListIsEmpty(
        $userListContainer,
        usersListObject,
        ObjectType.USERS,
        EventType.INITIAL
      )

      usersListObject.on("searchComplete", () => {
        Lists.checkIfListIsEmpty(
          $userListContainer,
          usersListObject,
          ObjectType.USERS,
          EventType.SEARCH_COMPLETE
        )
      })

      usersListObject.on("sortComplete", () => {
        Lists.checkIfListIsEmpty(
          $userListContainer,
          usersListObject,
          ObjectType.USERS,
          EventType.SORT_COMPLETE
        )
      })
    },
  },

  init($wrapper: JQuery): void {
    if (
      $wrapper.hasClass("create") ||
      $wrapper.hasClass("edit") ||
      $wrapper.hasClass("new") ||
      $wrapper.hasClass("update")
    ) {
      Users.edit.init($wrapper)
    }

    if ($wrapper.hasClass("index")) {
      Users.index.init($wrapper)
    }
  },
}

export default Users
