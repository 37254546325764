/**
 * Colors from app/assets/stylesheets/helpers/_variables.scss
 */

const blue = "#00a3e0"
const blueHover = "#0092c9"
const green = "#68cb9a"
const greenHover = "#3cb08d"
const greyBorders = "#e2e2f0"
const purple = "#403f4c"
const red = "#e84855"

export { blue, blueHover, green, greenHover, greyBorders, purple, red }
