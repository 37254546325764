import Dropzone from "dropzone"
import initialContent from "./react-prosemirror/initialContent"
import OSKAREditor from "./react-prosemirror/OSKAREditor"
import Dropzones, { OskarDropzoneFile, OskarFile } from "./common/dropzones"
import { measureDropzoneConfig } from "./measures"

function initNotes($wrapper: JQuery): void {
  const documentSections = $wrapper.find("section.documents")
  documentSections.each((_, documentSection) => {
    const $section = $(documentSection)
    const $dropzone = $section.find(".dropzone")
    if ($dropzone.length) initDropzone($section, $dropzone)
  })

  const jsonElement = document.getElementById("note-editor-json")
  if (jsonElement) initEditor(jsonElement)
}

function initDropzone($section: JQuery, $dropzone: JQuery) {
  const $formFiles = $section.find(".js-form-files")
  const $serverFiles = $formFiles.find("input.document-file")
  const serverFiles: OskarDropzoneFile[] = []
  const dropzone = new Dropzone(
    $dropzone.get(0)!,
    Dropzones.config(undefined, $dropzone.find(".files").get(0))
  )

  Dropzones.init({
    dropzone,
    ...measureDropzoneConfig,
  })

  $serverFiles.each(function () {
    const fileObject = JSON.parse($(this).val() as string),
      file: OskarFile = {
        accepted: true, // So we don't run unnecessary checks when adding existing files
        id: fileObject.id,
        height: fileObject.metadata.height,
        name: fileObject.metadata.filename,
        original_url: fileObject.original_url,
        response: fileObject,
        sha256: fileObject.metadata.sha256,
        size: fileObject.metadata.size,
        thumb_url: fileObject.thumb_url,
        type: fileObject.metadata.mime_type,
        width: fileObject.metadata.width,
      }

    serverFiles.push(file as OskarDropzoneFile)
  })

  Dropzones.addExistingFiles(dropzone, serverFiles)

  dropzone.files = serverFiles
}

function initEditor(jsonElement: HTMLElement) {
  const json = jsonElement.textContent
  const editorContent =
    json != undefined && json !== "" && json !== "{}"
      ? JSON.parse(json)
      : initialContent
  OSKAREditor.init(editorContent, false)
}

export { initNotes }
