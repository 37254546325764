import { Plugin } from "prosemirror-state"

// Replaces digits of ordered lists with trailing whitespace copied from word
// with just the digit and one space (that isn't matched).
export default (): Plugin => {
  return new Plugin({
    props: {
      transformPastedHTML(html) {
        return html.replace(/(\d+\.)<span .+?>(?:&nbsp;)+/g, "$1")
      },
    },
  })
}
