import BottomBar from "./common/bottombar"

const Accounts = {
  init() {
    BottomBar.show()
  },
}

$(document).ready(() => {
  const $wrapper = $("#wrapper")

  if ($wrapper.hasClass("accounts")) {
    Accounts.init()
  }
})
