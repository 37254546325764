import Dropzone, { DropzoneFile } from "dropzone"
import BottomBar from "./common/bottombar"
import Dropzones, {
  dictImageTooBig,
  dictResolutionTooBig,
  OskarDropzoneFile,
  UploadEndpointResponse,
} from "./common/dropzones"
import Forms from "./common/forms"
import Messages from "./common/messages"
import { initPhoneNumbers } from "./common/phone_number_utils"
import RelativeTime from "./common/relative_time"

const logoDropzoneConfig = {
  maxImageFileSizeInMegabyte: 10,
  maxImageWidth: 4096,
  maxImageHeight: 4096,
}

function addHiddenInputField(
  $formFiles: JQuery,
  response: UploadEndpointResponse
) {
  const input = document.createElement("input")
  input.type = "hidden"
  input.id = `company-logo-${response.metadata.sha256}`
  input.name = `company[logo]`
  input.value = JSON.stringify(response)

  $formFiles.append(input)
}

function removeHiddenInputField(
  $formFiles: JQuery,
  response?: UploadEndpointResponse
) {
  const $hiddenInputField = $formFiles.find(
    `#company-logo-${response?.metadata.sha256}`
  )

  $hiddenInputField.remove()
}

function mockFileFromServerLogo(): Dropzone.DropzoneMockFile | undefined {
  const $companyLogoInput = $(".company-logo")

  if ($companyLogoInput.length) {
    const logoObject = JSON.parse($companyLogoInput.val() as string)
    const mockFile: Dropzone.DropzoneMockFile = {
      accepted: true, // So we don't run unnecessary checks when adding existing files
      id: logoObject.id,
      height: logoObject.metadata.height,
      name: logoObject.metadata.filename,
      original_url: logoObject.original_url,
      response: logoObject,
      sha256: logoObject.metadata.sha256,
      size: logoObject.metadata.size,
      thumb_url: logoObject.thumb_url,
      type: logoObject.metadata.mime_type,
      width: logoObject.metadata.width,
    }

    return mockFile
  }
}

function addExistingLogo(dropzone: Dropzone, $form: JQuery<HTMLFormElement>) {
  const mockFile = mockFileFromServerLogo()

  if (!mockFile) return

  const resizeThumbnail = false
  dropzone.displayExistingFile(
    mockFile,
    mockFile.thumb_url,
    undefined,
    undefined,
    resizeThumbnail
  )

  // Not in official docs, but makes the code work.
  // Found here: https://stackoverflow.com/a/44718743
  dropzone.files.push(mockFile as DropzoneFile)

  /*
    Below code is according to the documentation, however setting it to 0
    (`maxFiles` is 1, `fileCountOnServer is `1`) results in endless recursion,
    so we don't use it.
    https://gitlab.com/meno/dropzone/-/wikis/faq#how-to-show-files-already-stored-on-server
  */
  // If you use the maxFiles option, make sure you adjust it to the
  // correct amount:
  // dropzone.options.maxFiles = 1 // (dropzone.options.maxFiles - fileCountOnServer)

  hideHintHeaderLogoAdded($(dropzone.element))
  initTrashButton(dropzone, $form)
}

function hideHintHeaderLogoAdded($dropzone: JQuery) {
  const $hint = $dropzone.find(".hint")
  const $hintIcon = $hint.find("svg")
  const $hintHeader = $hint.find(".hint-header")

  $hint.css("margin-top", "1rem")
  $hintIcon.hide()
  $hintHeader.hide()
}

function showHintHeaderLogoRemoved($dropzone: JQuery) {
  const $hint = $dropzone.find(".hint")
  const $hintIcon = $hint.find("svg")
  const $hintHeader = $hint.find(".hint-header")

  $hint.css("margin-top", "0rem")
  $hintIcon.show()
  $hintHeader.show()
}

function initTrashButton(dropzone: Dropzone, $form: JQuery<HTMLFormElement>) {
  const $trashButton = $(dropzone.element).find(".trash")
  const $hiddenRemoveLogoCheckbox = $("#company_remove_logo")

  $trashButton.on("click.oskar", () => {
    $hiddenRemoveLogoCheckbox.val("true")
    Forms.changesOccurred($form)
  })
}

function initDropzone($wrapper: JQuery) {
  const $form = $wrapper.find("form")
  const $formFiles = $form.find(".js-form-files")
  const $hiddenRemoveLogoCheckbox = $form.find("#company_remove_logo")
  const $dropzone = $wrapper.find(".dropzone")
  const $chooseButton = $dropzone.find(".btn.choose-files")
  const clickableElement = $chooseButton.get(0)
  const dropzoneConfig = Dropzones.config(clickableElement)

  // For a bit better recovery from when users drop either files that are too
  // big in size or resolution or invalid file types, we remember the previous
  // file. In case there is an error, we restore the previous, valid logo.
  let previousFile: DropzoneFile | undefined

  dropzoneConfig.maxFiles = 1
  dropzoneConfig.acceptedFiles = "image/jpeg, image/png, .jpeg, .png"
  dropzoneConfig.url = "/oskar/logo/upload"
  const dropzone = new Dropzone($dropzone.get(0)!, dropzoneConfig)

  Dropzones.init({
    dropzone,
    ...logoDropzoneConfig,
  })
  addExistingLogo(dropzone, $form)

  dropzone
    .on("addedfile", () => {
      // If there are too many files, remember the previous, valid logo in case
      // the new files gets removed because it is invalid.
      if (
        dropzoneConfig.maxFiles &&
        dropzone.files.length > dropzoneConfig.maxFiles
      ) {
        previousFile = dropzone.files[0]
        dropzone.removeFile(dropzone.files[0])
      }

      hideHintHeaderLogoAdded($dropzone)
      $hiddenRemoveLogoCheckbox.val("false")
    })
    .on("error", (file, message) => {
      if (!file.accepted && typeof message === "string") {
        // I couldn't find a better way to detect which kind of error was
        // thrown, so we do string matching. In case an invalid file type was
        // uploaded, we don't want to display a preview element with an error
        // message on the bottom. Instead, we display an error message that
        // automatically disappears after a bit.
        switch (message) {
          case dropzone.options.dictInvalidFileType:
          case dictResolutionTooBig(
            logoDropzoneConfig.maxImageWidth,
            logoDropzoneConfig.maxImageHeight
          ):
          case dictImageTooBig(logoDropzoneConfig.maxImageFileSizeInMegabyte):
            dropzone.removeFile(file)

            if (previousFile) {
              dropzone.files.push(previousFile)
              dropzone.emit("addedfile", previousFile)
              dropzone.emit("thumbnail", previousFile, previousFile.dataURL)
              dropzone.emit("complete", previousFile)
            }

            // Remove previous message. We currently don't support more than one
            // message and so for this to properly function, we have to remove
            // any previous message.
            // TODO: This can probably be in `setMessage()` directly.
            $("#message").remove()

            Messages.setMessage("alert", message)
            break
        }
      }
    })
    // `maxfilesexceeded` is a good idea, but only fires once, even if 5 files
    // are dropped. So `removeFiles()` would not remove all files, just the file
    // that causes this event to be emitted. .on("maxfilesexceeded", (file) =>
    // {dropzone.removeAllFiles() dropzone.addFile(file)
    // })
    .on("success", (_, response) => {
      addHiddenInputField($formFiles, response as UploadEndpointResponse)
      BottomBar.show()
    })
    .on("removedfile", (file) => {
      removeHiddenInputField($formFiles, (file as OskarDropzoneFile).response)

      if (dropzone.files.length === 0) {
        // Only if the last file was removed, we can show the hint again.
        showHintHeaderLogoRemoved($dropzone)
        // We also now know that any existing logo should be removed.
        $hiddenRemoveLogoCheckbox.val("true")
      }
    })

  $chooseButton.on("click.oskar", (event) => {
    event.preventDefault()
    event.stopPropagation()
  })
}

function initRelativeTimeFieldsForOSKARAdmins($wrapper: JQuery) {
  const $relativeTimeFields = $wrapper.find(".js-relative-time")

  if ($relativeTimeFields.length) {
    RelativeTime.setRelativeTimes($relativeTimeFields)
  }
}

const OrganizationManagement = {
  init($wrapper: JQuery): void {
    if ($wrapper.hasClass("edit") || $wrapper.hasClass("update")) {
      initPhoneNumbers($("form").not("[data-no-global-validation]"))
      initDropzone($wrapper)
      initRelativeTimeFieldsForOSKARAdmins($wrapper)
    }
  },
}

export default OrganizationManagement
