import { Chart, ArcElement, DoughnutController } from "chart.js"
import ChartPieLabels from "./chartjs-plugin-pie-labels"
import "@chenfengyuan/datepicker"
import "@chenfengyuan/datepicker/i18n/datepicker.de-AT"
import "@chenfengyuan/datepicker/src/css/datepicker.scss"
import dayjs from "dayjs"
import "dayjs/locale/de-at"
import customParseFormat from "dayjs/plugin/customParseFormat"
import includes from "lodash-es/includes"
import last from "lodash-es/last"
import Tooltips from "./common/tooltips"

dayjs.locale("de-at")
dayjs.extend(customParseFormat)

const colors = [
  "#4e79a7",
  "#f28e2c",
  "#e15759",
  "#76b7b2",
  "#59a14f",
  "#edc949",
  "#af7aa1",
  "#ff9da7",
  "#9c755f",
  "#bab0ab",
]

const options = {
  layout: {
    padding: {
      top: 60,
      left: 0,
      right: 0,
      bottom: 60,
    },
  },
  plugins: {
    legend: {
      display: false,
    },
  },
  responsive: true,
  maintainAspectRatio: false,
  // Make the charts crisp af for LDPI/MDPI and even some HDPI screens. Also
  // helps during print.
  devicePixelRatio: Math.max(window.devicePixelRatio, 3),
  // This might help with some blurryness, although I'm not convinced if this
  // really helps with donut charts. See:
  // https://github.com/chartjs/Chart.js/issues/9906.
  alignToPixels: true,
}

const BACKEND_DATE_FORMAT = "YYYY.MM.DD"
const BACKEND_MONTHS_WITH_DATA_DATE_FORMAT = "YYYY-MM-DD"
const USER_FACING_DATE_FORMAT = "MMMM YYYY"

function initDoughnut(data: number[] = [], labels: string[] = []) {
  const chartContainer = document.querySelector("#oskar-problem-measure-chart")

  if (chartContainer && data.length === 0) {
    chartContainer.innerHTML = "Keine Daten vorhanden."
    return
  }

  const chartData = {
    labels,
    datasets: [
      {
        data,
        backgroundColor: colors,
      },
    ],
  }

  Chart.register(ArcElement, DoughnutController)
  new Chart(
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    chartContainer!.querySelector("canvas")!,
    {
      type: "doughnut",
      data: chartData,
      options,
      plugins: [ChartPieLabels],
    }
  )
}

/*
  A few times in this function we might pass `undefined` to `dayjs()`. But
  that's okay and does what we want, which is, return the current date. As per
  the dayjs documentation:
  > Day.js treats `dayjs(undefined)` as `dayjs()` due to that function
    parameters default to undefined when not passed in.
  https://day.js.org/docs/en/parse/now
*/
function initMonthPicker(monthsWithData: string[], projectId: string) {
  const $datePickerInput = $<HTMLInputElement>('[data-toggle="datepicker"]')

  const params = new URLSearchParams(location.search)
  const month = params.get("month")
  // We have to check for `month` because if it was undefined, we'd get the
  // current date since dayjs treats `dayjs(undefined)` the same as `dayjs()`.
  //
  // If a month was provided in the URL path parameter, use it. Otherwise use
  // the latest month with data.
  let initialDayJsDate = month
    ? dayjs(month, BACKEND_DATE_FORMAT, true)
    : dayjs(last(monthsWithData))

  // Handle the case for when `month` is present, but not a valid date string.
  if (!initialDayJsDate.isValid()) {
    initialDayJsDate = dayjs(last(monthsWithData))
  }

  const initialDate = initialDayJsDate.startOf("month").toDate()

  $datePickerInput.datepicker({
    autoHide: true,
    autoPick: true,
    language: "de-AT",
    date: initialDate,
    // `mm.yyyy` lets datepicker select months as well as switch to a year view.
    // Without `.yyyy` it would let us switch years using left/right arrows but
    // not switch to a year view.
    format: "mm.yyyy",
    startDate: dayjs(monthsWithData[0]).startOf("year").toDate(),
    endDate: dayjs(last(monthsWithData)).endOf("year").toDate(),
    filter: (date, view) => {
      // In the month view, we want to filter the months. But in the year view,
      // if we'd apply the same logic and we'd have a date like '2017-09-01' it
      // would not let us select 2017 as a year. So we only apply the filter for
      // months. For years, the filtering is done automatically based on the
      // months.
      if (view === "month") {
        const formattedDate = dayjs(date).format(
          BACKEND_MONTHS_WITH_DATA_DATE_FORMAT
        )
        return includes(monthsWithData, formattedDate)
      }
    },
    pick: (event) => {
      // We prevent the default event, which means no `change` even will be
      // triggered either. We use this right now so the datepicker plugin does
      // not use its own date format when picking a date.
      event.preventDefault()

      // In case a year was picked, we simply return. We don't want a date to be
      // set in this case, only if individual months are selected.
      if (event.view === "year") {
        return
      }

      const pickedDayJsDate = dayjs(event.date)

      // The datepicker library has very limited formatting options, so we use
      // dayjs for it.
      const formattedDate = pickedDayJsDate.format(USER_FACING_DATE_FORMAT)
      $datePickerInput.val(formattedDate)

      // Set path parameter so people can easily copy URLs to specific months.
      const params = new URLSearchParams(location.search)
      params.set("month", pickedDayJsDate.format(BACKEND_DATE_FORMAT))
      window.history.replaceState({}, "", `${location.pathname}?${params}`)

      // Load the appropriate month.
      $.ajax({
        url: `/oskar/projects/${projectId}/analytics/monthly_data`,
        data: `month=${pickedDayJsDate.format(BACKEND_DATE_FORMAT)}`,
        dataType: "script",
        complete: () => {
          Tooltips.init()
        },
      })
    },
  })

  const $resetDateButton = $(".reset-date")
  // If we'd use our own tooltips helper here, the tooltip wouldn't go away
  // after clicking the anchor element because the element would be focused. By
  // only listening to `hover`, we get the desired behavior.
  $resetDateButton.tooltip({ trigger: "hover" })

  $resetDateButton.on("click", () => {
    $datePickerInput.datepicker("setDate", last(monthsWithData))
  })
}

// @ts-ignore
window.initDoughnut = initDoughnut
// @ts-ignore
window.initMonthPicker = initMonthPicker
