import { Chart, ArcElement, DoughnutController } from "chart.js"
import Cookies from "./common/cookies"
import Dialogs from "./common/dialogs"
import { Option } from "./common/dialogs"
import Dropzones from "./common/dropzones"
import {
  OskarFile,
  OskarDropzoneFile,
  UploadEndpointResponse,
} from "./common/dropzones"
import Forms from "./common/forms"
import { clone, debounce, findIndex, isEqual } from "lodash-es"
import List from "list.js"
import Checkboxes from "./common/checkboxes"
import Lists from "./common/lists"
import { EventType, FilterType, FilterValue, ObjectType } from "./common/lists"
import { red, green } from "./common/colors"
import OSKAREditor from "./react-prosemirror/OSKAREditor"
import initialContent from "./react-prosemirror/initialContent"
import {
  editorContentChanged,
  isEditorBlank,
} from "./react-prosemirror/utils/textUtils"
import { initListSorting } from "./helpers/sortOrderHelper"

function initCharts(
  $currentTask: JQuery<HTMLLIElement>,
  okCount: number,
  undefinedCount: number,
  shortcomingCount: number
): void {
  const $canvas = $currentTask.find("canvas")
  const chartData = {
    labels: ["Offen", "In Ordnung", "Mangelhaft"],
    datasets: [
      {
        data: [shortcomingCount, okCount, undefinedCount],
        backgroundColor: [red, green, "#dcdcdc"],
      },
    ],
  }

  Chart.register(ArcElement, DoughnutController)

  new Chart($canvas, {
    type: "doughnut",
    data: chartData,
    options: {
      animation: {
        animateRotate: true, // If true, will animate the rotation of the chart.
        animateScale: false, // If true, will animate scaling the Doughnut from the centre.
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      cutout: "30%", // The percentage of the chart that is cut out of the middle.
      responsive: false,
    },
  })

  $canvas.data("filled", true)
}

const Tasks = {
  index: {
    tasksList: {
      init($wrapper: JQuery): void {
        const $taskListContainer = $wrapper.find(".list-container.tasks")
        const $search = $taskListContainer.find(".search").find("input")
        const $dropdownState = $taskListContainer.find(".dropdown.state")
        const $dropdownStateSelect = $dropdownState.find("select")
        const taskListObject = new List($taskListContainer.get(0)!, {
          page: 1000,
          searchClass: "search-trigger",
          sortClass: "sort-trigger",
          valueNames: ["activity", "archived", "name"],
        })
        const permissions = JSON.parse($("#permissions").html())
        const filterCookieValue = Cookies.get("listTasksFilterState")
        const $archiveButton = $(".btn.js-archive")
        const $unarchiveButton = $(".btn.js-unarchive")

        const layoutList = function () {
          const $taskList = $taskListContainer.find(".list.tasks")
          const $taskListItems = $taskList.find("li")
          const userCanArchive = permissions.can_archive
          const userCanEdit = permissions.can_edit

          $taskListItems.each(function () {
            const $currentTask = $(this)
            const $currentTaskCanvas = $currentTask.find("canvas")
            const $okMeasures = $currentTask.find(".ok")
            const $undefinedMeasures = $currentTask.find(".open")
            const $shortcomingMeasures = $currentTask.find(".problem")
            const okCount = parseInt($okMeasures.find("label").text())
            const undefinedCount = parseInt(
              $undefinedMeasures.find("label").text()
            )
            const shortcomingCount = parseInt(
              $shortcomingMeasures.find("label").text()
            )
            const $quickActionsColumn = $currentTask.find(
              ".column.quickactions"
            )
            const archiveOrUnarchive = $quickActionsColumn.data("is-archived")
              ? "unarchive"
              : "archive"
            const $archiveButton = $quickActionsColumn.find(
              `.btn.icon-only.${archiveOrUnarchive}`
            )
            const $editButton = $quickActionsColumn.find(".btn.icon-only.edit")

            if (!$currentTaskCanvas.data("filled")) {
              initCharts(
                $currentTask,
                okCount,
                undefinedCount,
                shortcomingCount
              )
            }

            if (!userCanArchive) {
              $archiveButton.hide()
            }

            if (!userCanEdit) {
              $editButton.hide()
            }
          })

          Lists.layoutAndInit($taskListContainer)
        }

        const filterList = (filter: FilterType, value: FilterValue) => {
          Lists.filter($taskListContainer, taskListObject, filter, value)
          layoutList()
        }

        initListSorting(taskListObject, "task", $wrapper)

        if (filterCookieValue) {
          $dropdownStateSelect.val(filterCookieValue).trigger("change")
        }

        filterList(
          FilterType.ARCHIVED,
          $dropdownStateSelect.val() as FilterValue
        )

        $search.on(
          "change.oskar keyup.oskar",
          debounce(function (this: JQuery<HTMLInputElement>) {
            // @ts-ignore
            Lists.search(taskListObject, $(this).val())
          }, 150)
        )

        /**
         * Filter task list by the selected state.
         */
        $dropdownStateSelect.on("change.oskar", function () {
          filterList(FilterType.ARCHIVED, $(this).val() as FilterValue)
        })

        Lists.checkIfListIsEmpty(
          $taskListContainer,
          taskListObject,
          ObjectType.TASKS,
          EventType.INITIAL
        )

        taskListObject.on("filterComplete", () => {
          Lists.checkIfListIsEmpty(
            $taskListContainer,
            taskListObject,
            ObjectType.TASKS,
            EventType.FILTER_COMPLETE
          )
        })

        taskListObject.on("searchComplete", () => {
          Lists.checkIfListIsEmpty(
            $taskListContainer,
            taskListObject,
            ObjectType.TASKS,
            EventType.SEARCH_COMPLETE
          )
        })

        taskListObject.on("sortComplete", () => {
          Lists.checkIfListIsEmpty(
            $taskListContainer,
            taskListObject,
            ObjectType.TASKS,
            EventType.SORT_COMPLETE
          )
        })

        $archiveButton.on("click.oskar", () => {
          Cookies.set("listTasksFilterState", "archived", { expires: 365 })
        })

        $unarchiveButton.on("click.oskar", () => {
          Cookies.set("listTasksFilterState", "active", { expires: 365 })
        })
      },
    },

    init($wrapper: JQuery): void {
      Tasks.index.tasksList.init($wrapper)
      Tasks.initDialog($wrapper)
    },
  },

  show: {
    measuresList: {
      init($wrapper: JQuery): void {
        const $measureListContainer = $wrapper.find(
          ".list-container.task-measures"
        )

        Lists.layoutAndInit($measureListContainer)
      },
    },
    init($wrapper: JQuery): void {
      Tasks.show.measuresList.init($wrapper)
      Tasks.initDialog($wrapper)
    },
  },

  new: {
    init($wrapper: JQuery): void {
      const $evaluationTemplateSelect = $wrapper.find(
        "#evaluation_template_select"
      )

      Tasks.initDialog($wrapper)

      // Changed from "change.oskar" to "select2:select" when moved from
      // Sprockets to Webpacker. This is needed to fix
      // https://github.com/NR-Systems/OSKAR-Server/issues/2010
      $evaluationTemplateSelect.on("select2:select", function () {
        const selectedEvaluationTemplateMeasureIds = $(this)
          .find(":selected")
          .data("measureIds")
        const $newTaskForm = $wrapper.find<HTMLFormElement>("form.new.task")

        if (selectedEvaluationTemplateMeasureIds) {
          const measureIdsLength = selectedEvaluationTemplateMeasureIds.length

          // Uncheck all, this is how the apps do it
          Checkboxes.setUnchecked($newTaskForm.find("div.checkbox"))

          // Open the groups that contain measures, close the ones that don't
          $(".panel", $newTaskForm).each((_, panel) => {
            const $panel = $(panel)
            const $panelHeader = $panel.find(".panel-header")
            const $panelCollapse = $panel.find(".panel-collapse")
            let measureListItemFound = false
            let $measureListItem = null

            for (let i = 0; i < measureIdsLength; i++) {
              const measureId = selectedEvaluationTemplateMeasureIds[i]
              $measureListItem = $panel.find(`#measure-item-${measureId}`)

              if ($measureListItem.length) {
                measureListItemFound = true
                break
              }
            }

            $panelHeader.collapse(measureListItemFound ? "show" : "hide")
            $panelCollapse.collapse(measureListItemFound ? "show" : "hide")
          })

          for (let i = 0; i < measureIdsLength; i++) {
            const measureId = selectedEvaluationTemplateMeasureIds[i]
            const $measureCheckbox = $newTaskForm.find(
              `#measure-item-${measureId} > div.checkbox`
            )

            Checkboxes.setChecked($measureCheckbox)
          }
        }
      })
    },
  },

  edit: {
    init($wrapper: JQuery): void {
      Tasks.initDialog($wrapper)
    },
  },

  /**
   * Initializes a large dialog with text field, Dropzone etc.
   */
  initDialog($wrapper: JQuery): void {
    const $measureItems = $wrapper.find(".measure-item")

    const dispatchUnloadEvent = () => {
      // Taken from https://github.com/turbolinks/turbolinks/issues/146#issuecomment-234680174
      const event = document.createEvent("Event")
      event.initEvent("turbolinks:unload", true, false)
      document.dispatchEvent(event)
      // This fixes https://github.com/NR-Systems/OSKAR-Server/issues/1648
      // Without this, the storage would keep growing over time within a session
      // and the index is the measure ID, so measures with the same ID, even in
      // different tasks had the same dropzones.
      Dropzones.storage = []
    }

    addEventListener("beforeunload", dispatchUnloadEvent)
    addEventListener("turbolinks:before-render", dispatchUnloadEvent)

    $measureItems.each(function () {
      const measureName = $(this).find(".name").html()
      const $measureInfoData = $(this).find(".measure-info-data")
      const $measureInfoDataFiles = $measureInfoData.find(".files")
      const $measureInfoLink = $(this).find(".btn.edit, .btn.new, .btn.show")

      $measureInfoLink.on("click.oskar", function (event) {
        const $this = $(this)
        const measureID = $this.data("id")
        const hiddenIDFieldID = `measure-info-id-${measureID}`
        const hiddenIDFieldSelector = `#${hiddenIDFieldID}`
        const hiddenJSONFieldID = `measure-info-json-${measureID}`
        const hiddenJSONFieldSelector = `#${hiddenJSONFieldID}`
        const json = $(hiddenJSONFieldSelector).val() as string
        const $dialogPartial = $(`.js-dialog.js-dialog-measure-${measureID}`)
        const $dialogPartialBody = $dialogPartial.find(".body")
        let options: Option
        let editable = false

        event.preventDefault()
        event.stopPropagation()

        if ($dialogPartialBody.hasClass("locked")) {
          options = {
            actions: [
              {
                classes: "text-only success",
                goal: {
                  type: null,
                },
                order: "primary",
                string: "OK",
              },
            ],
            ID: measureID,
            title: measureName,
          }
        } else {
          editable = true
          options = {
            actions: [
              {
                classes: "text-only success",
                goal: {
                  method() {
                    const $dialog = $("#dialog")
                    const $dropzone = $dialog.find(".dropzone")
                    const $dropzoneFileContainer = $dropzone.find(".files")
                    const $files = $dropzoneFileContainer.find(".file")
                    const dropzoneFileArray = clone(
                      Dropzones.storage[measureID]["files"]
                    ) as OskarDropzoneFile[]
                    const tempFileArray: OskarFile[] = []
                    const time = new Date().getTime()
                    const $measureItem = $(`#measure-item-${measureID}`)
                    const $copyNote = $(`#copy-note-${measureID}`)
                    const $measureItemCheckbox = $measureItem.find(".checkbox")
                    const measureJSON = $dialog
                      .find(".editor-json")
                      .val() as string
                    const editorIsBlank = isEditorBlank(measureJSON)
                    const $hiddenIDField = $(hiddenIDFieldSelector)
                    const hiddenIDInput = document.createElement("input")
                    const hiddenJSONInput = document.createElement("input")
                    const hiddenNotesEdited = document.createElement("input")
                    const hiddenFileInputs: HTMLInputElement[] = []
                    let mustCopyNote = false
                    let filesChanged = false

                    hiddenNotesEdited.type = "hidden"
                    hiddenNotesEdited.name = "task[notes_edited]"
                    hiddenNotesEdited.value = "true"
                    $measureInfoDataFiles.before(hiddenNotesEdited)

                    hiddenIDInput.type = "hidden"
                    hiddenIDInput.id = hiddenIDFieldID
                    hiddenIDInput.name = `task[evaluations_attributes][0][evaluation_measure_notes_attributes][${time}][measure_id]`
                    hiddenIDInput.value = measureID

                    hiddenJSONInput.type = "hidden"
                    hiddenJSONInput.id = hiddenJSONFieldID
                    hiddenJSONInput.name = `task[evaluations_attributes][0][evaluation_measure_notes_attributes][${time}][editor_json]`

                    let $hiddenJSONField = $(hiddenJSONFieldSelector)

                    /**
                     * We remove the field before we add it instead of reusing the element
                     * because it contains a pseudo ID (`time`) which needs to be refreshed.
                     *
                     * Another option would be to search and replace the pseudo ID, but
                     * removing and adding two elements should be a cheap enough operation
                     * even if not ideal.
                     *
                     * The pseudo ID needs to be refreshed when the dialog gets opened and
                     * closed more than once. When pressing the save button, the new data
                     * is written out. Files are always fresh.
                     */
                    if (!editorIsBlank || $files.length) {
                      $hiddenIDField.remove()
                      $measureInfoDataFiles.before(hiddenIDInput)
                    } else {
                      // editor is blank & no files
                      // When deleting a note, app api first copies then deletes it
                      // so we need to do the same on desktop
                      mustCopyNote = true
                      const hiddenDeleteNotes = document.createElement("input")
                      hiddenDeleteNotes.type = "hidden"
                      hiddenDeleteNotes.name = "task[delete_notes][]"
                      hiddenDeleteNotes.value = measureID
                      $measureInfoDataFiles.before(hiddenDeleteNotes)
                      $hiddenIDField.remove()
                      $measureInfoDataFiles.remove()
                      $(hiddenJSONFieldSelector).remove()
                    }

                    if (!editorIsBlank || (editorIsBlank && $files.length)) {
                      $hiddenJSONField.remove()
                      $measureInfoDataFiles.before(hiddenJSONInput)
                      $hiddenJSONField = $(hiddenJSONFieldSelector)
                      $hiddenJSONField.val(measureJSON)
                    }

                    if (!mustCopyNote) $copyNote.remove()

                    if (!$dialogPartialBody.hasClass("locked")) {
                      if (!editorIsBlank || $files.length) {
                        $this
                          .removeClass("new")
                          .addClass("const edit")
                          .attr(
                            "data-original-title",
                            "Maßnahmeninfo bearbeiten"
                          )
                      } else {
                        $this
                          .removeClass("const edit")
                          .addClass("new")
                          .attr("data-original-title", "Maßnahmeninfo anlegen")
                      }
                    }

                    if ($files.length) {
                      $files.each(function (index) {
                        const fileID = $(this).data("id")
                        const dropzoneFileArrayIndex = findIndex(
                          dropzoneFileArray,
                          ["id", fileID]
                        )
                        const hiddenFileInput = document.createElement("input")
                        let dataObject: UploadEndpointResponse

                        if (dropzoneFileArrayIndex >= 0) {
                          const dropzoneFile =
                            dropzoneFileArray[dropzoneFileArrayIndex]
                          hiddenFileInput.type = "hidden"
                          hiddenFileInput.className = `measure-info-file-${measureID}`
                          hiddenFileInput.name = `task[evaluations_attributes][0][evaluation_measure_notes_attributes][${time}][evaluation_measure_note_documents_attributes][${index}][file]`

                          if (dropzoneFile.response !== undefined) {
                            dataObject = dropzoneFile.response

                            if (dropzoneFile.thumb_url) {
                              dataObject.thumb_url = dropzoneFile.thumb_url
                            }

                            if (dropzoneFile.storage) {
                              dataObject.storage = dropzoneFile.storage
                            }
                          } else {
                            const sha = dropzoneFile.sha256

                            dataObject = JSON.parse(
                              // @ts-ignore
                              document.getElementById(`file-${sha}`).value
                            )

                            hiddenFileInput.id = `file-${sha}`
                            hiddenFileInput.name = `task[evaluations_attributes][0][evaluation_measure_notes_attributes][${time}][evaluation_measure_note_documents_attributes][${index}][file_data]`
                          }

                          hiddenFileInput.value = JSON.stringify(dataObject)

                          hiddenFileInputs.push(hiddenFileInput)

                          tempFileArray.push(dropzoneFile)
                        }
                      })

                      // This works, but I don't know how to type it.
                      // @ts-ignore
                      Dropzones.storage[measureID].files = tempFileArray
                      // Check if initial file ids are equal to current file ids
                      filesChanged = !isEqual(
                        $measureInfoDataFiles
                          .find("input")
                          .map((_, input) => input.id)
                          .toArray()
                          .sort(),
                        hiddenFileInputs.map((input) => input.id).sort()
                      )
                      // While possible to use .html() here, jQuery doesn't
                      // officially support .html(elements: HTMLElement[]). So
                      // we .empty().append().
                      $measureInfoDataFiles.empty().append(hiddenFileInputs)
                    } else {
                      filesChanged =
                        $("input", $measureInfoDataFiles).length > 0
                      $measureInfoDataFiles.empty()
                    }

                    // editor or files changed
                    if (
                      editorContentChanged(json, measureJSON) ||
                      filesChanged
                    ) {
                      Forms.changesOccurred(
                        $<HTMLFormElement>("form").not(
                          "[data-no-global-validation]"
                        )
                      )
                    }
                    Checkboxes.setChecked($measureItemCheckbox)

                    Dropzones.initialFiles = []
                  },
                  type: "method",
                },
                order: "primary",
                string: "Speichern",
              },
              {
                classes: "text-only",
                goal: {
                  method() {
                    Dropzones.storage[measureID]["files"] =
                      Dropzones.initialFiles
                    Dropzones.initialFiles = []
                  },
                  parameters: null,
                  type: "method",
                },
                order: "secondary",
                string: "Abbrechen",
              },
            ],
            ID: measureID,
            title: measureName,
          }
        }

        Dialogs.large.show(options)
        if (!$dialogPartialBody.hasClass("no-text") || editable) {
          OSKAREditor.init(
            json !== undefined && json !== "" && json !== "{}"
              ? JSON.parse(json)
              : initialContent,
            editable
          )
        }
      })
    })
  },

  init($wrapper: JQuery): void {
    if ($wrapper.hasClass("index")) {
      Tasks.index.init($wrapper)
    } else if ($wrapper.hasClass("show")) {
      Tasks.show.init($wrapper)
    } else if ($wrapper.hasClass("new")) {
      Tasks.new.init($wrapper)
    } else if ($wrapper.hasClass("edit")) {
      Tasks.edit.init($wrapper)
    }
  },
}

export default Tasks
