import {
  MdFormatBold,
  MdFormatItalic,
  MdFormatUnderlined,
  MdFormatListBulleted,
  MdFormatListNumbered,
  MdFormatIndentDecrease,
  MdFormatIndentIncrease,
  MdUndo,
  MdRedo,
} from "react-icons/md"

const ICON_SIZE = 24

export default {
  em: <MdFormatItalic size={ICON_SIZE} />,
  italic: <MdFormatItalic size={ICON_SIZE} />,
  strong: <MdFormatBold size={ICON_SIZE} />,
  bold: <MdFormatBold size={ICON_SIZE} />,
  underline: <MdFormatUnderlined size={ICON_SIZE} />,
  orderedList: <MdFormatListNumbered size={ICON_SIZE} />,
  bulletList: <MdFormatListBulleted size={ICON_SIZE} />,
  lift: <MdFormatIndentDecrease size={ICON_SIZE} />,
  sink: <MdFormatIndentIncrease size={ICON_SIZE} />,
  undo: <MdUndo size={ICON_SIZE} />,
  redo: <MdRedo size={ICON_SIZE} />,
}
