import Cookies from "./common/cookies"
import Dialogs from "./common/dialogs"
import { Option } from "./common/dialogs"
import introJs from "intro.js"

import sprite from "../images/sprite.svg"
import "intro.js/introjs.css"

const Onboarding = {
  introJS: introJs(),
  cookieExpiry: { expires: 365 },

  setDefaultOptions(): void {
    Onboarding.introJS.setOptions({
      doneLabel: "Fertig",
      exitOnOverlayClick: false,
      nextLabel: "Weiter",
      prevLabel: "Zurück",
      showBullets: false,
      showButtons: false,
      showProgress: false,
      showStepNumbers: false,
      skipLabel: "Abbrechen",
    })
  },

  listenForDOMChanges(): void {
    let $tooltip: JQuery | undefined

    const addCancelButtonEventListener = function ($targetTooltip: JQuery) {
      const $cancelButton = $targetTooltip.find(".action .btn.cancel")

      if ($cancelButton.length) {
        $cancelButton.off("click.oskar").on("click.oskar", function () {
          Onboarding.guides.initial.finish()
          Onboarding.introJS.exit()
        })
      }
    }

    const addLinkButtonEventListener = function ($targetTooltip: JQuery) {
      const $linkButton = $targetTooltip.find(".action .btn.link")

      if ($linkButton.length) {
        $linkButton.off("click.oskar").on("click.oskar", function () {
          const guide = $(this).data("guide")
          const guidePart = $(this).data("guide-part")

          Cookies.set(`guide${guide}Part`, guidePart, Onboarding.cookieExpiry)
        })
      }
    }

    const addEventListeners = function ($modifiedElement: JQuery) {
      if ($modifiedElement.length && $modifiedElement.hasClass("action")) {
        $tooltip = $modifiedElement.parents(".introjs-tooltip")
      }

      if (
        $modifiedElement.length &&
        $modifiedElement.hasClass("introjs-tooltip")
      ) {
        $tooltip = $modifiedElement
      }

      if (
        $modifiedElement.length &&
        $modifiedElement.hasClass("introjs-tooltiptext")
      ) {
        $tooltip = $modifiedElement.parents(".introjs-tooltip")
      }

      if ($tooltip) {
        addCancelButtonEventListener($tooltip)
        addLinkButtonEventListener($tooltip)
      }

      $("body").trigger("oskar:onboarding:tooltip:changed")
    }

    const mutationObserver = new MutationObserver(function (mutations) {
      for (const mutation of mutations) {
        const mutationTarget = $(mutation.target as HTMLElement)

        addEventListeners(mutationTarget)
      }
    })

    // Define what element should be observed by the observer
    // and what types of mutations trigger the callback
    mutationObserver.observe(document, {
      attributeFilter: [],
      attributeOldValue: false,
      attributes: true,
      characterData: true,
      characterDataOldValue: false,
      childList: true,
      subtree: true,
    })
  },

  guides: {
    initial: {
      parts: {
        welcome: {
          start(): void {
            const options: Option = {
              title: "Willkommen bei OSKAR!",
              text: "Möchten Sie die ersten Schritte<br> mit dem Assistenten starten?",
              actions: [
                {
                  goal: {
                    method() {
                      Cookies.set(
                        "guideOnboardingPart",
                        "1",
                        Onboarding.cookieExpiry
                      )
                      // @ts-ignore
                      window.Turbolinks.visit("/oskar/users")
                    },
                    parameters: null,
                    type: "method",
                  },
                  order: "primary",
                  string: "Ja, Assistent starten",
                },
                {
                  goal: {
                    method() {
                      Onboarding.guides.initial.finish()
                    },
                    parameters: null,
                    type: "method",
                  },
                  order: "secondary",
                  string: "Nein, danke",
                },
              ],
            }

            Onboarding.listenForDOMChanges()

            Dialogs.small.question.answer(options)
          },
        },
        one: {
          start(): void {
            const $introElement = $(".js-intro-onboarding-2")

            const stepContent =
              '<div class="title">Die ersten Benutzer anlegen</div>' +
              '<div class="description">' +
              "<p>" +
              "Um bei der Erstellung Ihres ersten Projekts die beteiligten Benutzer " +
              "(Aufsichtspersonen, Sicherheitsfachkräfte etc.) auswählen zu können, müssen " +
              "diese zuerst angelegt werden. Legen Sie im nächsten Schritt einen am Projekt " +
              "beteiligten Benutzer an." +
              "</p>" +
              "</div>" +
              '<div class="hint">' +
              `<svg role="img" class="svg__arrow_right"><use class="arrow_right" xlink:href="${sprite}#arrow_right"/></svg>` +
              "Klicken Sie dazu auf die Schaltfläche „Neuer Benutzer“" +
              "</div>" +
              '<div class="action">' +
              '<button class="btn text-only cancel">Assistent abbrechen</button>' +
              "</div>"

            Onboarding.listenForDOMChanges()

            if ($introElement.length) {
              Onboarding.introJS = introJs()

              Onboarding.setDefaultOptions()

              Onboarding.introJS
                .setOptions({
                  steps: [
                    {
                      element: $introElement.get(0),
                      intro: stepContent,
                    },
                  ],
                })
                .start()

              $introElement.off("click.oskar").on("click.oskar", function () {
                Cookies.set("guideOnboardingPart", "2", Onboarding.cookieExpiry)
              })
            }
          },
        },

        two: {
          start(): void {
            const $introElement = $(".js-intro-onboarding-3")
            const $bottombar = $(".bottombar")
            const $btnSave = $bottombar.find(".btn.save")

            const stepContent =
              '<div class="title">Daten des Benutzers eingeben</div>' +
              '<div class="description">' +
              "<p>" +
              "Tragen Sie alle relevanten Daten des Benutzers in die angegebenen Felder ein. " +
              "Felder die mit einem * gekennzeichnet sind, müssen ausgefüllt werden." +
              "</p>" +
              "<p>" +
              "Sie können dem Benutzer eine Ihrer verfügbaren Lizenzen zuweisen. Nur " +
              "Benutzer mit zugewiesener Lizenz sind berechtigt die OSKAR App für " +
              "Android oder iOS zu verwenden." +
              "</p>" +
              "<p>" +
              "Wenn Sie alle Eingaben korrekt gemacht haben, können Sie den neuen Benutzer " +
              "durch einen Klick auf die Schaltfläche „Benutzer anlegen“ am unteren " +
              "Bildschirmrand anlegen." +
              "</p>" +
              "</div>" +
              '<div class="hint">' +
              `<svg role="img" class="svg__arrow_right"><use class="arrow_right" xlink:href="${sprite}#arrow_right"/></svg>` +
              "Beginnen Sie nun mit der Eingabe durch einen Klick auf das Auswahlfeld " +
              "„Anrede“" +
              "</div>" +
              '<div class="action">' +
              '<button class="btn text-only cancel">Assistent abbrechen</button>' +
              "</div>"

            Onboarding.listenForDOMChanges()

            Onboarding.introJS = introJs()

            Onboarding.setDefaultOptions()

            Onboarding.introJS
              .setOptions({
                steps: [
                  {
                    element: $introElement.get(0),
                    intro: stepContent,
                  },
                ],
              })
              .start()

            $introElement.on("click.oskar", function () {
              Onboarding.introJS.exit()
            })

            $btnSave.on("click.oskar", function () {
              Cookies.set("guideOnboardingPart", "3", Onboarding.cookieExpiry)
            })
          },
        },

        three: {
          start(): void {
            const $introElement = $(".js-intro-onboarding-2")

            const stepContent =
              '<div class="title">Weiteren Benutzer anlegen / Erstes Projekt anlegen</div>' +
              '<div class="description">' +
              "<p>" +
              "Sie haben erfolgreich einen Benutzer angelegt. Wenn Sie noch weitere Benutzer " +
              "(Aufsichtspersonen, Sicherheitsfachkräfte etc.) für Ihr erstes Projekt " +
              "benötigen, klicken Sie auf die Schaltfläche „Neuer Benutzer“." +
              "</p>" +
              "<p>" +
              "Haben Sie bereits alle erforderlichen Benutzer für Ihr erstes Projekt " +
              "angegelegt, dann fahren Sie mit einem Klick auf die Schaltfläche " +
              "„Projekt anlegen“ fort." +
              "</p>" +
              "</div>" +
              '<div class="action">' +
              '<button class="btn text-only cancel">Assistent abbrechen</button>' +
              '<a href="' +
              "/oskar/projects" +
              '" class="btn text-only link" data-guide="Onboarding" data-guide-part="4">' +
              "Projekt anlegen" +
              "</a>" +
              "</div>"

            Onboarding.listenForDOMChanges()

            if ($introElement.length) {
              Onboarding.introJS = introJs()

              Onboarding.setDefaultOptions()

              Onboarding.introJS
                .setOptions({
                  steps: [
                    {
                      element: $introElement.get(0),
                      intro: stepContent,
                    },
                  ],
                })
                .start()

              $introElement.off("click.oskar").on("click.oskar", function () {
                Cookies.set("guideOnboardingPart", "2", Onboarding.cookieExpiry)
              })
            }
          },
        },

        four: {
          start(): void {
            const $introElement = $(".js-intro-onboarding-4")

            const stepContent =
              '<div class="title">Das erste Projekt anlegen</div>' +
              '<div class="description">' +
              "<p>" +
              "Sie haben sämtliche erforderlichen Benutzer angelegt und sind nun bereit, Ihr " +
              "erstes Projekt anzulegen." +
              "</p>" +
              "</div>" +
              '<div class="hint">' +
              `<svg role="img" class="svg__arrow_right"><use class="arrow_right" xlink:href="${sprite}#arrow_right"/></svg>` +
              "Klicken Sie dazu auf die Schaltfläche „Neues Projekt“" +
              "</div>" +
              '<div class="action">' +
              '<button class="btn text-only cancel">Assistent abbrechen</button>' +
              "</div>"

            Onboarding.listenForDOMChanges()

            if ($introElement.length) {
              Onboarding.introJS = introJs()

              Onboarding.setDefaultOptions()

              Onboarding.introJS
                .setOptions({
                  steps: [
                    {
                      element: $introElement.get(0),
                      intro: stepContent,
                    },
                  ],
                })
                .start()

              $introElement.off("click.oskar").on("click.oskar", function () {
                Cookies.set("guideOnboardingPart", "5", Onboarding.cookieExpiry)
              })
            }
          },
        },

        five: {
          start(): void {
            const $introElement = $(".js-intro-onboarding-5")
            const $bottombar = $(".bottombar")
            const $btnSave = $bottombar.find(".btn.save")

            const stepContent =
              '<div class="title">Projektdetails eingeben</div>' +
              '<div class="description">' +
              "<p>" +
              "Tragen Sie alle relevanten Details des Projekts in die angegebenen Felder " +
              "ein. Felder die mit einem * gekennzeichnet sind, müssen ausgefüllt werden." +
              "</p>" +
              "<p>" +
              "Weisen Sie dem Projekt Benutzer zu, die in diesem Projekt beteiligt sind. " +
              "Ihnen stehen alle Benutzer zur Verfügung, die Sie bereits angelegt haben." +
              "</p>" +
              "<p>" +
              "Tragen Sie bei den einzelnen Benutzern eine Funktion (Bauleiter, " +
              "Sicherheitsfachkraft etc.) ein, um die Verantwortlichkeiten und " +
              "Aufgabenbereiche abzugrenzen." +
              "</p>" +
              "<p>" +
              "Wählen Sie für jeden beteiligten Benutzer eine Rolle (Standardbenutzer oder " +
              "Beobachter). Beobachter können das Projekt ansehen und die Inhalte lesen. " +
              "Um Aufgaben und Nachrichten erstellen und bearbeiten zu können, benötigt " +
              "ein Benutzer die Rolle „Standardbenutzer“." +
              "</p>" +
              "<p>" +
              "Sie können Benutzer die Projektverwaltung erlauben. Dadurch können diese die " +
              "Projektdetails bearbeiten und die Liste der am Projekt beteiligten Benutzer " +
              "verwalten." +
              "</p>" +
              "<p>" +
              "Wenn Sie alle Eingaben korrekt gemacht haben, können Sie das Projekt " +
              "durch einen Klick auf die Schaltfläche „Projekt anlegen“ am unteren " +
              "Bildschirmrand anlegen." +
              "</p>" +
              "</div>" +
              '<div class="hint">' +
              `<svg role="img" class="svg__arrow_right"><use class="arrow_right" xlink:href="${sprite}#arrow_right"/></svg>` +
              "Beginnen Sie nun mit der Eingabe durch einen Klick auf das Eingabefeld " +
              "„Projektname“" +
              "</div>" +
              '<div class="action">' +
              '<button class="btn text-only cancel">Assistent abbrechen</button>' +
              "</div>"

            Onboarding.listenForDOMChanges()

            Onboarding.introJS = introJs()

            Onboarding.setDefaultOptions()

            Onboarding.introJS
              .setOptions({
                steps: [
                  {
                    element: $introElement.get(0),
                    intro: stepContent,
                  },
                ],
              })
              .start()

            $introElement.on("click.oskar", function () {
              Onboarding.introJS.exit()
            })

            $btnSave.on("click.oskar", function () {
              Cookies.set("guideOnboardingPart", "6", Onboarding.cookieExpiry)
            })
          },
        },

        six: {
          start(): void {
            const options: Option = {
              title: "Erste Schritte abgeschlossen.",
              text: "Wir wünschen viel Erfolg<br>bei der Umsetzung Ihrer Projekte!",
              actions: [
                {
                  goal: {
                    method() {
                      Cookies.set(
                        "guideOnboarding",
                        "false",
                        Onboarding.cookieExpiry
                      )
                    },
                    parameters: null,
                    type: "method",
                  },
                  order: "primary",
                  string: "Ok",
                },
              ],
            }

            Onboarding.listenForDOMChanges()

            Dialogs.small.info.show(options)
          },
        },
      },

      finish(): void {
        Cookies.set("guideOnboarding", "false", Onboarding.cookieExpiry)
      },

      init(): void {
        Cookies.set("guideOnboarding", "true", Onboarding.cookieExpiry)
        Onboarding.guides.initial.parts.welcome.start()
      },
    },

    load($wrapper: JQuery): void | boolean {
      const guide = $wrapper.data("guide")
      const cookieGuideOnboardingPart = Cookies.get("guideOnboardingPart")
      let cookieGuideOnboarding = Cookies.get("guideOnboarding")

      if (cookieGuideOnboarding === undefined) {
        cookieGuideOnboarding = "true"
      }

      if (cookieGuideOnboarding === "true") {
        if (guide === "onboarding") {
          if (
            cookieGuideOnboardingPart === undefined &&
            ($wrapper.hasClass("projects") ||
              $wrapper.hasClass("measures") ||
              $wrapper.hasClass("users") ||
              $wrapper.hasClass("reports") ||
              $wrapper.hasClass("licenses"))
          ) {
            Onboarding.guides.initial.init()
            return true
          }

          if (
            cookieGuideOnboardingPart === "1" &&
            $wrapper.hasClass("users") &&
            $wrapper.hasClass("index")
          ) {
            Onboarding.guides.initial.parts.one.start()
            return true
          } else if (
            cookieGuideOnboardingPart === "2" &&
            $wrapper.hasClass("users") &&
            $wrapper.hasClass("new")
          ) {
            Onboarding.guides.initial.parts.two.start()
            return true
          } else if (
            cookieGuideOnboardingPart === "3" &&
            $wrapper.hasClass("users") &&
            $wrapper.hasClass("index")
          ) {
            Onboarding.guides.initial.parts.three.start()
            return true
          } else if (
            cookieGuideOnboardingPart === "4" &&
            $wrapper.hasClass("projects") &&
            $wrapper.hasClass("index")
          ) {
            Onboarding.guides.initial.parts.four.start()
            return true
          } else if (
            cookieGuideOnboardingPart === "5" &&
            $wrapper.hasClass("projects") &&
            $wrapper.hasClass("new")
          ) {
            Onboarding.guides.initial.parts.five.start()
            return true
          }
        } else {
          if (
            cookieGuideOnboardingPart === "6" &&
            $wrapper.hasClass("projects") &&
            $wrapper.hasClass("index")
          ) {
            Onboarding.guides.initial.parts.six.start()
            return true
          } else {
            return false
          }
        }
      }
    },
  },

  init(): void {
    const $wrapper = $("#wrapper")

    Onboarding.guides.load($wrapper)
  },
}

export default Onboarding
