import dayjs from "dayjs"
import "dayjs/locale/de-at"
import "@chenfengyuan/datepicker"
import "@chenfengyuan/datepicker/i18n/datepicker.de-AT"
import "@chenfengyuan/datepicker/src/css/datepicker.scss"
import * as Sentry from "@sentry/browser"
import customParseFormat from "dayjs/plugin/customParseFormat"
import isEmpty from "lodash-es/isEmpty"
import BottomBar from "./common/bottombar"
import Checkboxes from "./common/checkboxes"
import Messages from "./common/messages"
import { MDCFormField } from "@material/form-field"
import { MDCRadio } from "@material/radio"
import { MDCSwitch } from "@material/switch"
import {
  DataFormat,
  GroupedDataFormat,
  LoadingData,
  OptGroupData,
  OptionData,
  SearchOptions,
} from "select2"
import sprite from "../images/sprite.svg"
import { SentryError } from "entrypoints/application"

dayjs.locale("de-at")
dayjs.extend(customParseFormat)

type PrintOptions = {
  report_title: string
  company_logo: boolean
  mode_switch: "specific_tasks" | "no_tasks"
  period_radio: "entire" | "latest" | "custom"
  content_evaluation_switch: boolean
  content_documentation_switch: boolean
  measure_images_switch: boolean
  documentation_images_switch: boolean
  evaluation_images_switch: boolean
  selected_task_ids: number[]
  task_sort_order: "created_at" | "name"
  custom_period_from?: string
  custom_period_to?: string
}

const DATE_START_INVALID_FORMAT_ERROR =
  "Der Beginn des gewählten Zeitraums muss ein gültiges Datum im Format TT.MM.JJJJ enthalten."
const DATE_END_INVALID_FORMAT_ERROR =
  "Das Ende des gewählten Zeitraums muss ein gültiges Datum im Format TT.MM.JJJJ enthalten."
const DATE_END_IS_BEFORE_DATE_BEGIN_ERROR =
  "Das Ende des gewählten Zeitraums darf nicht vor dem Beginn liegen."

function archivedProjectsMatcher(materialArchivedProjectsSwitch: MDCSwitch) {
  return (params: SearchOptions, data: OptGroupData | OptionData) => {
    // @ts-ignore
    const originalMatcher = $.fn.select2.defaults.defaults.matcher
    const matches = originalMatcher(params, data)

    // Matches of the default matcher was `null`, no need for further checks.
    if (matches == null) return null

    // Project is archived and the switch to display archived projects isn't
    // selected. So don't display the item.
    if (
      data.element.dataset.archived == "true" &&
      !materialArchivedProjectsSwitch.selected
    ) {
      return null
    }

    // Else, return the matches of the default matcher.
    return matches
  }
}

function archivedProjectsTemplate(
  data: DataFormat | GroupedDataFormat | LoadingData
) {
  // @ts-ignore
  if (!data.element || data.element.dataset.archived == "false") {
    return data.text
  }

  const $data = $(
    `<span class="archived-projects-container">` +
      `<span class="archived-project-name">${data.text}</span>` +
      `<span class="archived-project-indicator">` +
      "(" +
      `<svg role="img" class="svg__button_archive"><use class="button__archive" xlink:href="${sprite}#button__archive"/></svg>` +
      "Archiviert" +
      ")" +
      "</span>" +
      "</span>"
  )
  return $data
}

function checkTasks(filter: "all" | "active" | "archived") {
  const $sectionTasks = $("section.tasks")
  const $tasksTable = $sectionTasks.find("table")
  const $tasksTableBody = $tasksTable.find("tbody")

  let inverseTableRowSelector = null
  let tableRowSelector = null

  switch (filter) {
    case "all":
      tableRowSelector = "tr"
      break
    case "active":
      tableRowSelector = 'tr[data-archived="false"]'
      inverseTableRowSelector = 'tr[data-archived="true"]'
      break
    case "archived":
      tableRowSelector = 'tr[data-archived="true"]'
      inverseTableRowSelector = 'tr[data-archived="false"]'
      break
  }

  const $checkboxesToBeChecked = $tasksTableBody
    .find(tableRowSelector)
    .find(".checkbox")

  $checkboxesToBeChecked.attr("data-clicked", "true")
  Checkboxes.setChecked($checkboxesToBeChecked)

  if (inverseTableRowSelector) {
    const $checkboxesToBeUnchecked = $tasksTableBody
      .find(inverseTableRowSelector)
      .find(".checkbox")
    $checkboxesToBeChecked.attr("data-clicked", "true")
    Checkboxes.setUnchecked($checkboxesToBeUnchecked)
  }
}

// https://github.com/iamkun/dayjs/issues/320#issuecomment-537885327
function validateDate(
  dayJsDate: dayjs.Dayjs,
  date: string,
  format = "DD.MM.YYYY"
) {
  return dayJsDate.isValid() && dayJsDate.format(format) === date
}

/**
 * @throws {Error} Will throw if one of the dates has an invalid format or if
 *    the end date is before the start date.
 */
function validateDateAndTimeRange(
  $dateStart: JQuery<HTMLInputElement>,
  $dateEnd: JQuery<HTMLInputElement>
): boolean | never {
  const DATE_FORMAT = "DD.MM.YYYY"
  const dateStartString = $dateStart.val() as string
  const dateEndString = $dateEnd.val() as string
  const dateStart = dayjs(dateStartString, DATE_FORMAT)
  const dateEnd = dayjs(dateEndString, DATE_FORMAT)
  const dateStartIsValid = validateDate(dateStart, dateStartString)
  const dateEndIsValid = validateDate(dateEnd, dateEndString)

  if (!dateStartIsValid || !dateEndIsValid) {
    const errorMessages: string[] = []

    if (!dateStartIsValid) {
      $dateStart.get(0)?.setCustomValidity(DATE_START_INVALID_FORMAT_ERROR)
      errorMessages.push(DATE_START_INVALID_FORMAT_ERROR)
    }

    if (!dateEndIsValid) {
      $dateEnd.get(0)?.setCustomValidity(DATE_END_INVALID_FORMAT_ERROR)
      errorMessages.push(DATE_END_INVALID_FORMAT_ERROR)
    }

    if (!isEmpty(errorMessages)) {
      throw new Error(errorMessages.join("<br>"))
    }
  } else if (dateEnd.isBefore(dateStart)) {
    $dateEnd.get(0)?.setCustomValidity(DATE_END_IS_BEFORE_DATE_BEGIN_ERROR)
    $dateStart.get(0)?.setCustomValidity(DATE_END_IS_BEFORE_DATE_BEGIN_ERROR)

    throw new Error(DATE_END_IS_BEFORE_DATE_BEGIN_ERROR)
  }

  return true
}

function clearDateErrors($dates: JQuery) {
  const $dateInputs = $dates.find<HTMLInputElement>(".date")

  $dateInputs.each((_, element) => {
    // Removes the `:invalid` pseudo-selector.
    element.setCustomValidity("")
  })

  $dates.find(".error").fadeOut((element: HTMLElement) => {
    $(element).empty()
  })

  // Don't wait until this error message would automatically hide but remove it
  // along with the input element errors.
  $("#message").slideToggle((element: JQuery) => {
    element.remove()
  })
}

const Reports = {
  init(): void {
    const $projectSelect = $("#project_select")
    const $sectionAppendices = $("section.appendices")
    const $sectionContent = $("section.content")
    const $sectionPeriod = $("section.period")
    const $sectionTasks = $("section.tasks")
    const $sectionTasksSortOrder = $("section.task-sort-order")
    const $radios = $(".radio")
    const $radioPeriodCustom = $radios.find("#radio__period_custom")
    const $radioPeriodEntire = $radios.find("#radio__period_entire")
    const $radioPeriodLatest = $radios.find("#radio__period_latest")
    const $dates = $(".dates")
    const $dateStart = $dates.find<HTMLInputElement>(".start")
    const $dateEnd = $dates.find<HTMLInputElement>(".end")
    const $bottomBar = $(".bottombar")
    const $btnCreatePdf = $bottomBar.find(".btn.confirm")
    const $loadingView = $(".loading-view")
    const $reportTitleInput = $(".report-title input")
    const archivedProjectsSwitch = document.querySelector<HTMLButtonElement>(
      ".project-select .mdc-switch"
    )
    const logoSwitch = document.querySelector<HTMLButtonElement>(
      ".cover-page .mdc-switch"
    )
    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    const printEvaluationSwitch = new MDCSwitch(
      document.querySelector<HTMLButtonElement>("#switch__content-evaluation")!
    )
    const printDocumentationSwitch = new MDCSwitch(
      document.querySelector<HTMLButtonElement>(
        "#switch__content-documentation"
      )!
    )
    const printMeasureImageSwitch = new MDCSwitch(
      document.querySelector<HTMLButtonElement>("#switch__measure-images")!
    )
    const printDocumentationImageSwitch = new MDCSwitch(
      document.querySelector<HTMLButtonElement>(
        "#switch__documentation-images"
      )!
    )
    const printEvaluationImageSwitch = new MDCSwitch(
      document.querySelector<HTMLButtonElement>("#switch__evaluation-images")!
    )
    /* eslint-enable @typescript-eslint/no-non-null-assertion */
    const $taskFilterAll = $sectionTasks.find(".btn#all")
    const $taskFilterOnlyActive = $sectionTasks.find(".btn#only-active")
    const $taskFilterOnlyArchived = $sectionTasks.find(".btn#only-archived")

    if (archivedProjectsSwitch == null) {
      Sentry.captureMessage(
        `'archivedProjectsSwitch' element was null.`,
        SentryError
      )
    } else {
      const projectsSelect2Options =
        $projectSelect.data("select2").options.options

      projectsSelect2Options.matcher = archivedProjectsMatcher(
        new MDCSwitch(archivedProjectsSwitch)
      )
      projectsSelect2Options.templateResult = archivedProjectsTemplate
    }

    $taskFilterAll.on("click.oskar", () => {
      $taskFilterAll.addClass("active")
      $taskFilterOnlyActive.removeClass("active")
      $taskFilterOnlyArchived.removeClass("active")
      checkTasks("all")
    })

    $taskFilterOnlyActive.on("click.oskar", () => {
      $taskFilterAll.removeClass("active")
      $taskFilterOnlyActive.addClass("active")
      $taskFilterOnlyArchived.removeClass("active")
      checkTasks("active")
    })

    $taskFilterOnlyArchived.on("click.oskar", () => {
      $taskFilterAll.removeClass("active")
      $taskFilterOnlyActive.removeClass("active")
      $taskFilterOnlyArchived.addClass("active")
      checkTasks("archived")
    })

    let selectedProjectID = null
    let selectedTasks: number[] = []
    let period: PrintOptions["period_radio"]
    let materialLogoSwitch: MDCSwitch | null

    if (logoSwitch) {
      materialLogoSwitch = new MDCSwitch(logoSwitch)
      materialLogoSwitch.disabled = true
    }

    const hideReportsSections = () => {
      $sectionAppendices.hide()
      $sectionContent.hide()
      $sectionPeriod.hide()
      $sectionTasks.hide()
      $sectionTasksSortOrder.hide()
    }

    const showReportsSections = () => {
      $sectionAppendices.fadeIn()
      $sectionContent.fadeIn()
      $sectionPeriod.fadeIn()
      $sectionTasks.fadeIn()
      $sectionTasksSortOrder.fadeIn()
    }

    const initRadioButtons = () => {
      document.querySelectorAll(".mdc-form-field").forEach((element) => {
        const formField = new MDCFormField(element)
        const radioElement = element.querySelector(".mdc-radio")
        if (radioElement == null) return
        const radio = new MDCRadio(radioElement)
        formField.input = radio
      })
    }

    const resetPrintOptionsSwitches = () => {
      printDocumentationSwitch.selected = true
      printEvaluationSwitch.selected = true
      printDocumentationImageSwitch.selected = false
      printEvaluationImageSwitch.selected = false
      printMeasureImageSwitch.selected = false
    }

    const initProjectEmptyView = () => {
      const $emptyView = $("#project-empty-view")

      if (!isEmpty($projectSelect.val())) {
        $emptyView.hide()
      } else {
        $emptyView.fadeIn()
      }
    }

    const updateTaskList = () => {
      selectedProjectID = $projectSelect.val()

      if (isEmpty(selectedProjectID)) {
        hideReportsSections()
      } else {
        $loadingView.show()
        hideReportsSections()

        $.ajax({
          url: "/oskar/reports/update_tasks",
          data: `project_id=${selectedProjectID}&mode=specific_tasks`,
          dataType: "script",
          complete() {
            checkTasks("all")
            $taskFilterAll.addClass("active")
            $taskFilterOnlyActive.removeClass("active")
            $taskFilterOnlyArchived.removeClass("active")

            $loadingView.hide()
            showReportsSections()
            BottomBar.show()
            initRadioButtons()
            resetPrintOptionsSwitches()
            $('[data-toggle="datepicker"]').datepicker({
              autoHide: true,
              language: "de-AT",
            })
          },
        })
      }
    }

    const updateLogoSwitch = (selectedProjectID: number | string) => {
      $.get({
        url: "/oskar/reports/logo_status",
        data: `project_id=${selectedProjectID}`,
        dataType: "json",
      }).done((data) => {
        if (data.data.project_owner_has_logo) {
          enableLogoSwitch()
        } else {
          disableLogoSwitch()
        }
      })
    }

    const enableLogoSwitch = () => {
      const $tooltip = $(".cover-page .info")

      if (materialLogoSwitch) {
        materialLogoSwitch.disabled = false
        materialLogoSwitch.selected = true
      }

      $tooltip.attr(
        "data-original-title",
        "Druckt das Firmenlogo des Projekterstellers auf der Titelseite des Berichts"
      )
    }

    const disableLogoSwitch = () => {
      const $tooltip = $(".cover-page .info")

      if (materialLogoSwitch) {
        materialLogoSwitch.disabled = true
        materialLogoSwitch.selected = false
      }

      $tooltip.attr(
        "data-original-title",
        "Der Projektersteller hat noch kein Logo hinterlegt."
      )
    }

    const checkForProjectIDinURL = () => {
      const urlParameters = new URLSearchParams(
        document.location.search.substring(1)
      )

      if ((selectedProjectID = urlParameters.get("project_id"))) {
        $projectSelect.val(selectedProjectID).trigger("change.select2")
      }
    }

    // This used to listen to 'change.oskar', but doesn't work anymore
    $projectSelect.on("change.select2", (event) => {
      selectedProjectID = $(event.currentTarget).val()

      if (selectedProjectID) {
        initProjectEmptyView()
        resetPrintOptionsSwitches()
        updateTaskList()
        updateLogoSwitch(selectedProjectID as number | string)
      }
    })

    checkForProjectIDinURL()
    initProjectEmptyView()

    $radioPeriodEntire.on("click.oskar", () => {
      clearDateErrors($dates)
    })

    $radioPeriodLatest.on("click.oskar", () => {
      clearDateErrors($dates)
    })

    $dateEnd.on("focusin.oskar", () => {
      $radioPeriodCustom.trigger("click.oskar")
    })

    $dateStart.on("focusin.oskar", () => {
      $radioPeriodCustom.trigger("click.oskar")
    })

    $dateStart.on("keyup.oskar change.oskar", () => {
      clearDateErrors($dates)
    })

    $dateEnd.on("keyup.oskar change.oskar", () => {
      clearDateErrors($dates)
    })

    $btnCreatePdf.prop("disabled", false).on("click.oskar", () => {
      const selectedProjectID = $("#project_select").val()
      const dateStartString = $dateStart.val() as string
      const dateEndString = $dateEnd.val() as string
      const $tableContainer = $(".table-inner-container")
      const $table = $tableContainer.find("table")
      const $tbody = $table.find("tbody")
      const $selectedTaskCheckboxes = $tbody.find(".checkbox.checked")
      const reportTitle = $reportTitleInput.val() as string
      const taskSortOrder = $(
        'input[name="radio__task_sort_order"]:checked'
      ).val() as PrintOptions["task_sort_order"]
      let mode: PrintOptions["mode_switch"] = "specific_tasks"

      selectedTasks = []

      if (selectedProjectID === undefined || selectedProjectID === null) {
        $("#message").remove()
        Messages.setMessage("alert", "Bitte wählen Sie zuvor ein Projekt aus.")
        return false
      }

      if ($radioPeriodEntire.prop("checked")) {
        period = "entire"
      } else if ($radioPeriodLatest.prop("checked")) {
        period = "latest"
      } else {
        period = "custom"
      }

      if (period === "custom") {
        try {
          validateDateAndTimeRange($dateStart, $dateEnd)
        } catch (error) {
          const dateErrorMessage = (error as Error).message
          const flashErrorMessage =
            "Der angegebene Zeitraum ist ungültig, bitte überprüfen Sie die Angaben."

          $dates.find(".error").html(dateErrorMessage).show()
          $("#message").remove()
          Messages.setMessage("alert", flashErrorMessage)
          return false
        }
      }

      // Beware when trying to convert jQuery's .each() to use arrow functions.
      // `this` doesn't mean the same in arrow functions and makes these loops
      // break. See also:
      // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Functions/Arrow_functions#Description
      $selectedTaskCheckboxes.each(function () {
        if ($(this).siblings('input[type="hidden"]').is("input")) {
          selectedTasks.push(
            $(this).siblings('input[type="hidden"]').val() as number
          )
        }
      })

      if (isEmpty(selectedTasks)) {
        mode = "no_tasks"
      }

      const printOptions: PrintOptions = {
        report_title: reportTitle,
        company_logo: materialLogoSwitch?.selected || false,
        mode_switch: mode,
        period_radio: period,
        content_evaluation_switch: printEvaluationSwitch.selected,
        content_documentation_switch: printDocumentationSwitch.selected,
        measure_images_switch: printMeasureImageSwitch.selected,
        documentation_images_switch: printDocumentationImageSwitch.selected,
        evaluation_images_switch: printEvaluationImageSwitch.selected,
        selected_task_ids: selectedTasks,
        task_sort_order: taskSortOrder,
      }

      if ($radioPeriodCustom.prop("checked")) {
        printOptions.custom_period_from = dateStartString
        printOptions.custom_period_to = dateEndString
      }

      const url = `/oskar/reports/project/${selectedProjectID}.pdf?${$.param(
        printOptions
      )}`

      window.open(url, "_blank")
    })
  },
}

export default Reports
