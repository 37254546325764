import debounce from "lodash-es/debounce"
import List from "list.js"
import Lists from "./common/lists"

function initIndex($wrapper: JQuery): void {
  const $evaluationTemplateListContainer = $wrapper.find(
    ".list-container.evaluation-templates"
  )
  const $search = $evaluationTemplateListContainer.find(".search").find("input")

  if (!$evaluationTemplateListContainer.length) {
    return
  }

  const evaluationTemplateListObject = new List(
    $evaluationTemplateListContainer.get(0)!,
    {
      searchClass: "search-trigger",
      sortClass: "sort-trigger",
      valueNames: ["name"],
    }
  )

  $search.on(
    "change.oskar keyup.oskar",
    debounce(function (this: JQuery<HTMLInputElement>) {
      Lists.search(evaluationTemplateListObject, $(this).val() as string)
    }, 150)
  )

  $evaluationTemplateListContainer.find("li").css({ opacity: 1 })
}

const EvaluationTemplates = {
  init($wrapper: JQuery): void {
    if ($wrapper.hasClass("index")) {
      initIndex($wrapper)
    }
  },
}

export default EvaluationTemplates
