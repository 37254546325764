import { Plugin } from "prosemirror-state"

// On every paste from Word there's around 85KB of "noise", we're only
// interested in the data between the StartFragment and EndFragment tags. To
// avoid unwanted behavior, we remove the rest.
export default (): Plugin => {
  return new Plugin({
    props: {
      transformPastedHTML(html) {
        const beforeFragment = new RegExp(".*<!--StartFragment-->", "s"),
          afterFragment = new RegExp("<!--EndFragment-->.*", "s")

        return html.replace(beforeFragment, "").replace(afterFragment, "")
      },
    },
  })
}
