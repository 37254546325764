import Dialogs from "../common/dialogs"
import { Option } from "../common/dialogs"
type BeforeVisitEvent = Event & { data: { url: string } }

const AbortDialogHelper = {
  init(): void {
    document.addEventListener("turbolinks:before-visit", (event) => {
      const $forms = $("form")
      const destinationURL = (event as BeforeVisitEvent).data.url
      const activeElement = (event.target as Document).activeElement
      const $bottombar = $(".bottombar")
      const $dialog = $("#dialog.question")
      const $dialogPrimaryAction = $dialog.find(".btn.primary")
      let formNeedsConfirm = false
      let dialogOptions: Option

      // Check if a button in the bottom bar or the primary action button in the
      // 'Änderungen verwerfen?' dialog was the original event target and if so, bypass the dialog
      if (activeElement) {
        const $originalEventTarget = $(activeElement)
        if (
          $originalEventTarget.parents(".bottombar").is($bottombar) ||
          $originalEventTarget.is($dialogPrimaryAction)
        ) {
          return true
        }
      }

      $forms.each(function () {
        if (
          $(this).attr("data-changed") === "true" &&
          $(this).attr("data-save-mode") === "true"
        ) {
          formNeedsConfirm = true
          return
        }
      })

      if (formNeedsConfirm) {
        if (!$dialog.is(":visible")) {
          dialogOptions = {
            title: "Änderungen verwerfen?",
            text: "Dadurch gehen alle nicht gespeicherten Änderungen verloren.",
            actions: [
              {
                goal: {
                  type: "link",
                  url: destinationURL,
                },
                order: "primary",
                string: "Ja, verwerfen",
              },
              {
                goal: {
                  type: "none",
                },
                order: "secondary",
                string: "Abbrechen",
              },
            ],
          }

          Dialogs.small.question.answer(dialogOptions)

          event.preventDefault()
          return false
        }
      }
    })
  },
}

export default AbortDialogHelper
