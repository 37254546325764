import { PropsWithChildren, ReactNode } from "react"
import map from "lodash-es/map"
import classnames from "classnames"
import classes from "./MenuBar.module.css"
import { EditorView } from "prosemirror-view"
import { MenuItemSpec } from "prosemirror-menu"
import { EditorMenu } from "./menu"

// No idea how to properly fix this.
// eslint-disable-next-line react/display-name
const Button = (view: EditorView) => (item: MenuItemSpec, key: string) => {
  const state = view.state
  const dispatch = view.dispatch

  const ButtonComponent = (
    <button
      key={key}
      type={"button"}
      className={classnames({
        [classes.button]: true,
        [classes.active]: item.active && item.active(state),
      })}
      title={item.title as string}
      disabled={(item.enable && !item.enable(state)) || undefined}
      onMouseDown={(e) => {
        e.preventDefault()
        item.run(state, dispatch, view, e.nativeEvent)
      }}
    >
      {item.icon as ReactNode}
    </button>
  )

  return ButtonComponent
}

type MenuBarProps = PropsWithChildren<{
  menu: EditorMenu
  view: EditorView
}>

const MenuBar = ({ menu, view, children }: MenuBarProps): JSX.Element => {
  return (
    <div className={classes.bar}>
      {children && <span className={classes.group}>{children}</span>}

      {map(menu, (item, key) => (
        <span key={key} className={classes.group}>
          {map(item, Button(view))}
        </span>
      ))}
    </div>
  )
}

export default MenuBar
