type MessageType = "alert" | "success"

function isFooterAtBottom($footer: JQuery): boolean {
  const top = $footer.offset()?.top
  const footerHeight = $footer.outerHeight()
  const windowHeight = $(window).height()

  if (footerHeight != null && windowHeight != null && top != null) {
    return windowHeight - top - footerHeight == 0
  }

  return false
}

const Messages = {
  // Don't trust 'Find all references', we also use this in `.js.erb` files.
  checkForMessage(): void {
    const $message = $("#message")
    const isConstant = $message.hasClass("js-const")
    const $bottomBar = $(".bottombar")
    const $footer = $("#dialog > .footer")
    let timeout = 6000

    if ($message.length) {
      if ($bottomBar.is(":visible")) {
        $message.css({ bottom: "8rem" })
      }

      if ($footer.is(":visible") && isFooterAtBottom($footer)) {
        $footer.animate({ bottom: "8rem" })
      }

      $message.slideToggle(() => {
        if ($message.hasClass("alert")) {
          timeout = 8000
        }

        if (!isConstant) {
          setTimeout(() => {
            $message.slideToggle(() => {
              $message.remove()
            })
            $footer.animate({ bottom: 0 })
          }, timeout)
        }
      })
    }
  },

  setMessage(type: MessageType, text: string): void {
    const $body = $("body")
    const messageHTML =
      `<div id="message" class="${type}" style="display: none;">` +
      '<div class="container">' +
      `<p>${text}</p>` +
      "</div>" +
      "</div>"

    $body.append(messageHTML)
    this.checkForMessage()
  },

  init(): void {
    this.checkForMessage()
  },
}

// TODO: Remove if we can remove this from .js.erb files
// @ts-ignore
window.Messages = Messages

export default Messages
