import { InputRule, inputRules, emDash, ellipsis } from "prosemirror-inputrules"

export default inputRules({
  rules: [
    ellipsis,
    emDash,

    // "Smart" opening double quotes.
    new InputRule(/(?:^|[\s{[(<'"\u2018\u201C])(")$/, "„"),
    // "Smart" closing double quotes.
    new InputRule(/"$/, "“"),
    // :: InputRule "Smart" opening single quotes.
    new InputRule(/(?:^|[\s{[(<'"\u2018\u201C])(')$/, "‚"),
    // :: InputRule "Smart" closing single quotes.
    new InputRule(/'$/, "‘"),
  ],
})
