import { Plugin } from "prosemirror-state"

// Replaces bullets of bullet lists copied from word with nothing. The otherwise
// resulting markup is not really useful and leads to more confusion than making
// users care for it themselves.
export default (): Plugin => {
  return new Plugin({
    props: {
      transformPastedHTML(html) {
        return html
          .replace(/·/g, "")
          .replace(/&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/g, "")
      },
    },
  })
}
