import { Node } from "prosemirror-model"
import { Plugin } from "prosemirror-state"
import { Decoration, DecorationSet } from "prosemirror-view"

// Taken from
// https://github.com/remirror/remirror/blob/3b9cee410ed597d4f7ad028cfe0fc668fcb10417/%40remirror/core-utils/src/dom-utils.ts#L214-L234
const isDocNodeEmpty = (node: Node) => {
  const nodeChild = node.content.firstChild

  if (node.childCount !== 1 || !nodeChild) {
    return false
  }

  return (
    nodeChild.type.isBlock &&
    !nodeChild.childCount &&
    nodeChild.nodeSize === 2 &&
    (nodeChild.marks == null || nodeChild.marks.length === 0)
  )
}

export default (): Plugin => {
  return new Plugin({
    props: {
      decorations: (state) => {
        const decorations: Decoration[] = []

        const decorate = (node: Node, pos: number) => {
          if (isDocNodeEmpty(state.doc)) {
            decorations.push(
              Decoration.node(pos, pos + node.nodeSize, {
                class: "empty-node",
              })
            )
          }
        }

        state.doc.descendants(decorate)

        return DecorationSet.create(state.doc, decorations)
      },
    },
  })
}
