import Cookies from "./common/cookies"

const ExpiringLicensesWarning = {
  init(): void {
    const $warningMessage = $("#license-warning")

    if (!$warningMessage.length) {
      return
    }

    $("#close-license-warning").on("click", (event) => {
      event.preventDefault()
      event.stopPropagation()

      $warningMessage.slideUp()
      Cookies.set("license_warning_closed", "true", { expires: 1 })
    })
  },
}

export default ExpiringLicensesWarning
