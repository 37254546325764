/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// To be safe and robust, polyfills/shims must run before all other code
// https://webpack.js.org/guides/shimming/
// NOTE: 2021-03-25: Removed IE11 support. All APIs we use should now be
// supported by browsers directly.
// import "../src/polyfills"

// @ts-ignore
window.jQuery = $
// @ts-ignore
window.$ = $

import "bootstrap3/js/collapse"
import "bootstrap3/js/transition"
import "jquery.turbolinks/vendor/assets/javascripts/jquery.turbolinks"
import "../src/chartjs-dayjs-adapter"
import "chartkick/chart.js"
import AbortDialogsHelper from "../src/helpers/abort_dialog_helper"
import Buttons from "../src/common/buttons"
import Checkboxes from "../src/common/checkboxes"
import ExpiringLicensesWarning from "../src/expiring_licenses_warning"
import Fields from "../src/common/fields"
import Forms from "../src/common/forms"
import Messages from "../src/common/messages"
import "../src/common/photoswipe"
import Onboarding from "../src/onboarding"
import PanelGroups from "../src/common/panel_groups"
import Selects from "../src/common/selects"
import Tables from "../src/common/tables"
import Tooltips from "../src/common/tooltips"
import FilterHelper from "../src/helpers/filterHelper"
import WindowHelper from "../src/helpers/window_helper"
import "../src/accounts"
import Documentations from "../src/documentations"
import EvaluationTemplates from "../src/evaluation_templates"
import "../src/header"
import OrganizationManagement from "../src/organization_management"
import Licenses from "../src/licenses"
import Measures from "../src/measures"
import Projects from "../src/projects"
import { initNotes } from "../src/notes"
import "../src/project_analytics"
import Reports from "../src/reports"
import Tasks from "../src/tasks"
import "../src/turbolinks_compatibility"
import Users from "../src/users"
import Rails from "@rails/ujs"
import * as Sentry from "@sentry/browser"
import { SeverityLevel } from "@sentry/types"
import Turbolinks from "turbolinks"

Rails.start()

const SentryError = "error" as SeverityLevel
const SentryWarning = "warning" as SeverityLevel
Sentry.init({
  dsn: "https://2e75a4cea86446d590194b4382a20a61@sentry.io/1456820",
  /*
    In development, log JavaScript errors to the console. Supposedly it should
    do this by default since 5.9, however this does not seem to be the case for
    me and some others on GitHub.
    My assumption is that this is only correct for unhandled exceptions, not for
    caught exceptions, like we sometimes have.

    https://github.com/getsentry/sentry-javascript/issues/1600#issuecomment-557231315
  */
  beforeSend: (event, hint) => {
    if (process.env.NODE_ENV == "development") {
      console.error(
        hint?.originalException || hint?.syntheticException || event
      )
      return null // this drops the event and nothing will be sent to sentry
    }
    return event
  },
})
Turbolinks.start()

document.addEventListener("turbolinks:load", () => {
  Buttons.init()
  Fields.init()
  Forms.init()
  Checkboxes.init()
  Messages.init()
  PanelGroups.init()
  Onboarding.init()
  Selects.init()
  Tables.init()
  Tooltips.init()
  ExpiringLicensesWarning.init()
  AbortDialogsHelper.init()
  FilterHelper.init()
  WindowHelper.init()

  $(document).on("oskar:tables:init", () => {
    Tables.init()
  })

  const $wrapper = $("#wrapper")

  if ($wrapper.hasClass("projects")) {
    Projects.init($wrapper)
  } else if ($wrapper.hasClass("tasks")) {
    Tasks.init($wrapper)
  } else if ($wrapper.hasClass("documentations")) {
    Documentations.init($wrapper)
  } else if ($wrapper.hasClass("measures")) {
    Measures.init($wrapper)
  } else if ($wrapper.hasClass("licenses")) {
    Licenses.init()
  } else if ($wrapper.hasClass("evaluation_templates")) {
    EvaluationTemplates.init($wrapper)
  } else if ($wrapper.hasClass("reports")) {
    Reports.init()
  } else if ($wrapper.hasClass("users")) {
    Users.init($wrapper)
  } else if ($wrapper.hasClass("organization_management")) {
    OrganizationManagement.init($wrapper)
  } else if ($wrapper.hasClass("evaluation_measure_notes")) {
    initNotes($wrapper)
  } else if ($wrapper.hasClass("projects/analytics")) {
    // initDoughnut()
  }
})

export { SentryError, SentryWarning }
