import Dropzone from "dropzone"
import Rails from "@rails/ujs"
import * as Sentry from "@sentry/browser"
import Sortable from "sortablejs"
import BottomBar from "./common/bottombar"
import Dropzones, { Restriction } from "./common/dropzones"
import {
  OskarDropzoneFile,
  OskarFile,
  UploadEndpointResponse,
} from "./common/dropzones"
import RelativeTime from "./common/relative_time"
import Tooltips from "./common/tooltips"
import { SentryError, SentryWarning } from "entrypoints/application"

const SORT_MODE_ACTIVE_CLASS = "sort-mode-active"
const DROPPED_ITEM_CLASS = "moved"

const measureDropzoneConfig: Restriction = {
  maxImageFileSizeInMegabyte: 15,
  maxPdfFileSizeInMegabyte: 50,
  maxImageWidth: 6144,
  maxImageHeight: 6144,
}

function addHiddenInputField(
  $formFiles: JQuery,
  response: UploadEndpointResponse
) {
  const input = document.createElement("input")
  input.type = "hidden"
  input.id = `document-file-${response.metadata.sha256}`
  input.name = `measure[measure_documents_attributes][${new Date().getTime()}][file]`
  input.value = JSON.stringify(response)

  $formFiles.append(input)
}

function removeHiddenInputField($formFiles: JQuery, file: OskarDropzoneFile) {
  const $hiddenInputField = $formFiles.find(
    `#document-file-${file.response?.metadata.sha256}`
  )

  $hiddenInputField.remove()
}

function removeHiddenInputFieldOnEdit(
  $formFiles: JQuery,
  file: OskarDropzoneFile
) {
  const $hiddenInputFileField = $formFiles.find(
    `#document-file-${file.response?.metadata.sha256}`
  )
  const $hiddenInputDestroyField = $formFiles.find(
    `#document-destroy-${file.response?.metadata.sha256}`
  )

  /*
   * File already exists, we rendered this element on the server, just
   * set `_destroy` to true.
   * Otherwise, remove the <input> element so it won't be uploaded.
   */
  if ($hiddenInputDestroyField.length) {
    $hiddenInputDestroyField.val("true")
  } else {
    $hiddenInputFileField.remove()
  }

  BottomBar.show()
}

function initDropzone($wrapper: JQuery, $dropzone: JQuery) {
  const $formFiles = $wrapper.find(".js-form-files")
  const $serverFiles = $formFiles.find("input.document-file")
  const serverFiles: OskarDropzoneFile[] = []
  const $chooseButton = $dropzone.find(".btn.choose-files")
  const clickableElement = $chooseButton.get(0)

  const dropzone = new Dropzone(
    $dropzone.get(0)!,
    Dropzones.config(clickableElement)
  )

  Dropzones.init({
    dropzone,
    ...measureDropzoneConfig,
  })

  dropzone
    .on("success", (file, response) => {
      addHiddenInputField($formFiles, response as UploadEndpointResponse)
      BottomBar.show()
    })
    .on("removedfile", (file) => {
      removeHiddenInputFieldOnEdit($formFiles, file as OskarDropzoneFile)
    })

  $chooseButton.on("click.oskar", (event) => {
    event.preventDefault()
    event.stopPropagation()
  })

  $serverFiles.each(function () {
    const fileObject = JSON.parse($(this).val() as string),
      file: OskarFile = {
        accepted: true, // So we don't run unnecessary checks when adding existing files
        id: fileObject.id,
        height: fileObject.metadata.height,
        name: fileObject.metadata.filename,
        original_url: fileObject.original_url,
        response: fileObject,
        sha256: fileObject.metadata.sha256,
        size: fileObject.metadata.size,
        thumb_url: fileObject.thumb_url,
        type: fileObject.metadata.mime_type,
        width: fileObject.metadata.width,
      }

    serverFiles.push(file as OskarDropzoneFile)
  })

  Dropzones.addExistingFiles(dropzone, serverFiles)

  dropzone.files = serverFiles
}

// Couldn't find an official API for this, so here goes.
// https://stackoverflow.com/a/54682715/1928168
function setSearchPlaceholder($wrapper: JQuery) {
  $wrapper.find("#measure_parent_id").on("select2:open", (event) => {
    $(".select2-search__field").attr(
      "placeholder",
      $(event.target).data("search-placeholder")
    )
  })
}

function setNewCategory($wrapper: JQuery) {
  $wrapper.find("#measure_parent_id").on("select2:select", (e) => {
    $("#new_category").val((!e.params.data.element).toString())
  })
}

/*
  When coming to the index page, we want this tooltip to be disabled. It is then
  toggled, depending on the state of the sort mode.
*/
function disableTooltipForNewCategoryButton($wrapper: JQuery) {
  const $newCategoryButtonContainer = $wrapper.find(
    "#new-category-button-container"
  )

  $newCategoryButtonContainer.tooltip("disable")
}

function toggleDisabledStateForSortModeButton($wrapper: JQuery) {
  const $sortModeButtonContainer = $wrapper.find(
    "#sort-measures-button-container"
  )
  const $sortModeButton = $sortModeButtonContainer.find("#sort-measures-button")
  const $companyMeasureSection = $wrapper.find("#accordion-measures-custom")
  const noCategoryIsBeingEdited =
    $companyMeasureSection.find("form").length == 0

  if (noCategoryIsBeingEdited) {
    $sortModeButtonContainer.tooltip("disable")
    $sortModeButton.removeClass("disabled")
  } else {
    $sortModeButtonContainer.tooltip("enable")
    $sortModeButton.addClass("disabled")
  }
}

function initCategorySelect($wrapper: JQuery) {
  setSearchPlaceholder($wrapper)
  setNewCategory($wrapper)
}

/**
  @param $wrapperOrForm This function can be called with the `#wrapper` element
  as a parameter as well as the `form` element that itself where the maximum
  length indicator will be rendered.
*/
function initMaxLengthIndicator($wrapperOrForm: JQuery) {
  const $newCategoryForm = $wrapperOrForm.find("form.measure_categories").length
    ? $wrapperOrForm.find("form.measure_categories")
    : $wrapperOrForm
  const $fieldWrapper = $newCategoryForm.find(".input.measure_name")
  const $saveCategoryButton = $newCategoryForm.find(".btn.icon-only.save")
  const $saveCategoryButtonContainer = $newCategoryForm.find(
    "#new-category-save-button-container"
  )
  const $errorElement = $fieldWrapper.find(".error")
  const $inputElement = $fieldWrapper.find("input[maxlength]")
  const maxLengthString = $inputElement.attr("maxlength")
  const maxCharacterMessage = $inputElement.data("max-character-message")
  const minCharacterMessage = $inputElement.data("min-character-message")
  const saveCategoryMessage = $saveCategoryButtonContainer.data(
    "save-category-message"
  )

  if (maxLengthString == undefined) {
    Sentry.captureMessage(`'maxlength' attribute was not set.`, SentryError)
    return
  }
  if (maxCharacterMessage == undefined) {
    Sentry.captureMessage(`'max-character-message' was not set.`, SentryWarning)
  }

  const maxLength = parseInt(maxLengthString)
  const displayThreshold = Math.max(maxLength * 0.05, 5)

  $inputElement.on("input", function () {
    const length = ($(this).val() as string).trim().length

    if (length > maxLength - displayThreshold)
      $errorElement.html(`${maxCharacterMessage}: ${length}/${maxLength}`)
    else $errorElement.html("")

    if (length == 0 && !$saveCategoryButton.is(":disabled")) {
      $saveCategoryButton.prop("disabled", true)
      $saveCategoryButtonContainer.attr(
        "data-original-title",
        minCharacterMessage
      )
    } else if (length > 0) {
      if ($saveCategoryButton.is(":disabled"))
        $saveCategoryButton.prop("disabled", false)

      $saveCategoryButtonContainer.attr(
        "data-original-title",
        saveCategoryMessage
      )
    }
  })

  // Trigger the event once ourselves, so we run the checks even before user
  // input.
  $inputElement.trigger("input")
}

function isHTMLElement(node: Node): node is HTMLElement {
  return node instanceof HTMLElement
}

/**
 * Categories on `Measure#index` can get added, removed (by aborting creation),
 * edited. We listen to these changes and act accordingly.
 *
 * @todo We should probably also `.disconnect()` at some point, but when?
 */
function observePageForCategoryChanges($wrapper: JQuery) {
  const observer = new MutationObserver((mutationsList) => {
    for (const mutation of mutationsList) {
      if (mutation.type === "childList") {
        Array.from(mutation.addedNodes)
          .filter(isHTMLElement)
          .forEach((addedNode) => {
            // The `addedNode` can be either the `form` itself or a `.panel`
            // that contains it. So we do a little "hack". We got an element
            // that is a descendant of both and get the parent, which should
            // always be the form.
            const $categoryForm = $(addedNode)
              .find(".input.measure_name")
              .parent()

            // This is a newly created category.
            if (addedNode.hasAttribute("data-sortable-category-id")) {
              toggleDisabledStateForSortModeButton($wrapper)
              Tooltips.build($(addedNode).find('[data-toggle="tooltip"'))
            }

            // A category was renamed and a new rename button was rendered.
            // Initialize its tooltip.
            if (addedNode.matches('a.btn.edit[data-toggle="tooltip"]')) {
              Tooltips.build($(addedNode))
            }

            if ($categoryForm.length) {
              initMaxLengthIndicator($categoryForm)
              initMeasureCategoryForm($categoryForm)
              toggleDisabledStateForSortModeButton($wrapper)
            }
          })

        Array.from(mutation.removedNodes)
          .filter(isHTMLElement)
          .forEach((removedNode) => {
            if (removedNode.classList.contains("measure_categories")) {
              toggleDisabledStateForSortModeButton($wrapper)
            }
          })
      }
    }
  })

  observer.observe(document.body, {
    childList: true,
    subtree: true,
  })
}

function checkIfListEmptyAndToggleEmptyView(measureList: HTMLElement) {
  const children = measureList.children
  const childrenArray = Array.from(children)
  const emptyView = measureList.querySelector(".no-drag")
  const filteredChildren = childrenArray.filter((child) => {
    // We only care about the elements that can be dragged.
    return !child.classList.contains("no-drag")
  })

  if (filteredChildren.length) {
    emptyView?.classList.add("hidden")
  } else {
    emptyView?.classList.remove("hidden")
  }
}

/*
  Initialize the click listener for the new category cancel button.
*/
function initMeasureCategoryForm($form: JQuery) {
  $form.find("#new-category-save-button-container").tooltip("enable")
  $form.find("#new-category-cancel-button-container").tooltip("enable")

  $form.find(".btn.cancel").on("click.oskar", (event) => {
    event.preventDefault()

    const isEditForm = $form.hasClass("edit")

    if (isEditForm) $form.siblings(".panel-heading").show()

    $form.remove()
  })
}

function initSortModeButton($wrapper: JQuery) {
  const $newCategoryButton = $wrapper.find("#new_category_button")
  const $newCategoryButtonContainer = $wrapper.find(
    "#new-category-button-container"
  )
  const $sortModeButton = $wrapper.find("#sort-measures-button")
  const $companyMeasureSection = $wrapper.find("#accordion-measures-custom")
  const sortModeActiveButtonText = $sortModeButton.data("sort-mode-active-text")
  const sortModeInactiveButtonText = $sortModeButton.data(
    "sort-mode-inactive-text"
  )

  $sortModeButton.on("click.oskar", (event) => {
    event.preventDefault()

    const $panelHeadings = $companyMeasureSection.find(".panel-heading")
    const $panelBodies = $companyMeasureSection.find(".panel-body")
    const $panelListItems = $panelBodies.find("li")
    const $sortModeInfoBox = $wrapper.find(".sort-info-container")

    if ($companyMeasureSection.hasClass(SORT_MODE_ACTIVE_CLASS)) {
      destroySorting()
      $sortModeButton.find("span").text(sortModeActiveButtonText)
      $sortModeButton.removeClass("active")

      $companyMeasureSection.removeClass(SORT_MODE_ACTIVE_CLASS)
      $panelHeadings.removeClass(SORT_MODE_ACTIVE_CLASS)
      $panelListItems.removeClass(SORT_MODE_ACTIVE_CLASS)
      $sortModeInfoBox.removeClass(SORT_MODE_ACTIVE_CLASS)

      $newCategoryButton.removeClass("disabled")
      $newCategoryButtonContainer.tooltip("disable")

      $panelHeadings.removeClass(DROPPED_ITEM_CLASS)
      $panelListItems.removeClass(DROPPED_ITEM_CLASS)
    } else {
      initSorting($wrapper)
      $sortModeButton.find("span").text(sortModeInactiveButtonText)
      $sortModeButton.addClass("active")

      $companyMeasureSection.addClass(SORT_MODE_ACTIVE_CLASS)
      $panelHeadings.addClass(SORT_MODE_ACTIVE_CLASS)
      $panelListItems.addClass(SORT_MODE_ACTIVE_CLASS)
      $sortModeInfoBox.addClass(SORT_MODE_ACTIVE_CLASS)

      $newCategoryButton.addClass("disabled")
      $newCategoryButtonContainer.tooltip("enable")
    }
  })
}

function updateOrderOnServer(
  measureId: number,
  newPosition: number,
  parentId = 0
) {
  const url = `/oskar/measures/${measureId}/sort_order`
  const data = {
    new_position: newPosition.toString(),
    parent_id: parentId.toString(),
  }
  const options = {
    url,
    type: "PATCH",
    // Can't just send JSON, sadly. See:
    // https://stackoverflow.com/a/59340407/1928168
    data: new URLSearchParams(data).toString(),
  }

  // `Rails.ajax` saves us the trouble of getting the CSRF token, setting
  // headers etc.
  Rails.ajax({
    ...options,
    error(_data) {
      Sentry.captureMessage(
        `Could not sort measure with ID: ${measureId} to position ${newPosition} into parent with ID: ${parentId}.`,
        SentryWarning
      )
    },
  })
}

function initSorting($wrapper: JQuery) {
  /*
    While there is a jQuery integration for SortableJS, I'd rather not add a
    small wrapper and potentially miss on type safety (I couldn't find types for
    it) just to be able to use jQuery.
    https://github.com/SortableJS/jquery-sortablejs

    So instead, we use the DOM APIs directly, even if there is a bit of overlap
    of the DOM queries below.
  */
  const parents = document.querySelector<HTMLElement>(
    "#accordion-measures-custom"
  )
  const children = parents?.querySelectorAll<HTMLElement>(".panel-body")
  const $panelHeadings = $wrapper
    .find("#accordion-measures-custom")
    .find(".panel-heading")

  if (!parents) {
    Sentry.captureMessage(
      "Measure categories were `null` or `undefined`, can't sort.",
      SentryWarning
    )

    return
  }

  if (!children) {
    Sentry.captureMessage(
      "Measure children were `null` or `undefined`, can't sort.",
      SentryWarning
    )

    return
  }

  children.forEach((child) => {
    Sortable.create(child, {
      // We'll enable multiDrag in the future, but first I want to be able to
      // drag individual items. Additionally, there's a bug in multi-drag with a
      // fix that has not yet been released.
      // https://github.com/SortableJS/Sortable/issues/2021
      multiDrag: false,
      // When a user drags a measure outside the container, we'd like the
      // measure to return to its original position.
      revertOnSpill: true,
      // Activate the AutoScroll plugin, which defaults to `true` anyway. But
      // we'd like to be explicit about our configuration. Setting it to `true`
      // does not mean the plugin will be used, only that it _could_ be used, if
      // needed.
      scroll: true,
      // To drag elements from one list into another, both lists must have the
      // same group value.
      group: "children",
      // CSS class of our drag handle. Instead of the whole element being
      // draggable, we define a small drag handle element with which the measure
      // can be dragged.
      handle: ".btn.drag",

      // As of writing, we still have empty views in categories that don't
      // disappear at the moment of dragging a measure into an empty category.
      // For us to get the correct indices of elements, we need to define two
      // options. `draggable` to define only the elements that can be dragged,
      // _and_ `filter` to tell it which elements cannot be dragged. See:
      // https://github.com/SortableJS/Sortable/issues/1564
      //
      // Example: The empty view is position 0, we drag a measure to the spot
      // after the empty view. `newIndex` would be 1. However, we need the index
      // of the measure without the empty view. So setting these two options
      // will give us `newDraggableIndex` with a value of 0.
      // Empty view index:    0
      // `newIndex`:          1
      // `newDraggableIndex`: 0 (empty view not counted)
      draggable: "> li:not(.no-drag)",
      filter: ".no-drag",

      onStart() {
        /*
          Open the measure category accordion after a users has dragged a child
          measure over it for a certain amount of time defined in the timeout. We
          store this timeout in the jQuery key-value data store so that if the user
          does not want to drop the measure or is simply moving the mouse over this
          category, we don't open the category.

          In other words, this prevents every category from opening the instant they
          receive the `dragenter` event.
        */
        $panelHeadings
          .on("dragenter", function () {
            const $self = $(this)
            $self.data(
              "timeout",
              setTimeout(() => {
                $self.siblings(".panel-collapse").collapse("show")
              }, 750)
            )
          })
          .on("dragleave", function () {
            clearTimeout($(this).data("timeout"))
          })
      },
      onEnd(event) {
        /*
          Once an item is dropped, we give it a different background color so
          users know which element was moved.
          If the the source (`to`) and destination (`from`) lists are the same
          as well as the new and old index, the item didn't move. Don't
          highlight a change.
        */
        if (!(event.to == event.from && event.newIndex == event.oldIndex))
          event.item.classList.add(DROPPED_ITEM_CLASS)

        /*
          The list from where this element came from is now potentially empty.
          Check if it is and toggle the empty view if needed.
        */
        checkIfListEmptyAndToggleEmptyView(event.from)

        /*
          The list where this element was dragged to is now definitely not
          empty. Toggle the empty view if needed.
        */
        checkIfListEmptyAndToggleEmptyView(event.to)

        /*
          Once dragging of child measures has stopped, we make sure measure
          categories don't open if any other element or categories themselves are
          dragged over them. Only while child measures are being dragged we
          should automatically open closed/collapsed categories.
        */
        $panelHeadings.off("dragenter")
        $panelHeadings.off("dragleave")

        if (
          // Some of these values could be `0`, which would evaluate to `false`
          // in JavaScript. So we explicitly check for `null`.
          event.newDraggableIndex != null &&
          event.to.dataset.sortableParentId != null &&
          event.item.dataset.childId != null
        ) {
          const measureId = parseInt(event.item.dataset.childId)
          const parentId = parseInt(event.to.dataset.sortableParentId)
          updateOrderOnServer(measureId, event.newDraggableIndex, parentId)
        }

        // DEBUG
        // console.debug(`Moved item ID: ${event.item.dataset.childId}`)
        // console.debug("Target list:")
        // console.debug(event.to) // target list
        // console.debug("Source list:")
        // console.debug(event.from) // previous list
        // console.debug(`Target parent id: ${event.to.dataset.sortableParentId}`)
        // console.debug(
        //   `Source parent id: ${event.from.dataset.sortableParentId}`
        // )
        // NOTE: Old an new parent can be the same.
        // console.debug(`Old index: ${event.oldIndex}`) // element's old index within old parent
        // console.debug(`New index: ${event.newIndex}`) // element's new index within new parent

        // console.debug(
        //   `Measure "${
        //     event.item.querySelector("a")?.text
        //   }" moved from position ${event.oldIndex} to position ${
        //     event.newIndex
        //   }, including any empty view elements.\nIt was moved from position ${
        //     event.oldDraggableIndex
        //   } to position ${
        //     event.newDraggableIndex
        //   } when filtering empty views.\nIt was moved from category "${
        //     parents?.querySelector<HTMLSpanElement>(
        //       `#measure_${event.from.dataset.sortableParentId} span`
        //     )?.textContent
        //   }" to category "${
        //     parents?.querySelector<HTMLSpanElement>(
        //       `#measure_${event.to.dataset.sortableParentId} span`
        //     )?.textContent
        //   }".`
        // )
        // DEBUG
      },
    })
  })

  // Parents are easier to configure. Just let them be dragged.
  Sortable.create(parents, {
    handle: ".btn.drag",
    onChoose() {
      /*
        Usually we'd use `.collapse("hide")` instead of doing the work manually.
        However, Bootstrap v3 doesn't have the option to deactivate the collapse
        animation. So we remove and add classes manually to immediately collapse
        without delay.

        This is done so that when users start dragging, the contained measures
        are hidden and just the dragged category is shown and not the list
        elements as well.
      */
      $(parents).find(".panel-collapse").removeClass("in")
      $(parents).find("h4 a").addClass("collapsed")
    },
    onEnd(event) {
      /*
        Once an item is dropped, we give it a different background color so
        users know which element was moved.
        If the new and old index are the same, the item didn't move. Don't
        highlight a change.
      */
      if (event.newIndex != event.oldIndex)
        event.item
          .querySelector(".panel-heading")
          ?.classList.add(DROPPED_ITEM_CLASS)

      if (
        // Some of these values could be `0`, which would evaluate to `false`
        // in JavaScript. So we explicitly check for `null`.
        event.newDraggableIndex != null &&
        event.item.dataset.sortableCategoryId != null
      ) {
        const categoryId = parseInt(event.item.dataset.sortableCategoryId)
        updateOrderOnServer(categoryId, event.newDraggableIndex)
      }
    },
  })
}

function destroySorting() {
  const parents = document.querySelector<HTMLElement>(
    "#accordion-measures-custom"
  )
  const children = parents?.querySelectorAll<HTMLElement>(".panel-body")
  if (parents) Sortable.get(parents)?.destroy()
  children?.forEach((child) => {
    Sortable.get(child)?.destroy()
  })
}

const Measures = {
  init($wrapper: JQuery): void {
    if ($wrapper.hasClass("index")) {
      observePageForCategoryChanges($wrapper)
      disableTooltipForNewCategoryButton($wrapper)
      toggleDisabledStateForSortModeButton($wrapper)
      initSortModeButton($wrapper)
    }

    if ($wrapper.hasClass("new")) {
      Measures.new.init($wrapper)
      initCategorySelect($wrapper)
      initMaxLengthIndicator($wrapper)
    }

    if ($wrapper.hasClass("edit")) {
      Measures.edit.init($wrapper)
      initCategorySelect($wrapper)
      initMaxLengthIndicator($wrapper)
    }
  },

  new: {
    init($wrapper: JQuery): void {
      const $formFiles = $wrapper.find(".js-form-files")
      const $dropzone = $wrapper.find(".dropzone")
      const $chooseButton = $dropzone.find(".btn.choose-files")
      const clickableElement = $chooseButton.get(0)
      const dropzone = new Dropzone(
        $dropzone.get(0)!,
        Dropzones.config(clickableElement)
      )

      Dropzones.init({
        dropzone,
        ...measureDropzoneConfig,
      })

      dropzone
        .on("success", (_file, response) => {
          addHiddenInputField($formFiles, response as UploadEndpointResponse)
        })
        .on("removedfile", (file) => {
          removeHiddenInputField($formFiles, file as OskarDropzoneFile)
        })

      $chooseButton.on("click.oskar", (event) => {
        event.preventDefault()
        event.stopPropagation()
      })
    },
  },

  edit: {
    init($wrapper: JQuery): void {
      const $dropzone = $wrapper.find(".dropzone")
      const $relativeTimeFields = $wrapper.find(".js-relative-time")

      if ($dropzone.length) initDropzone($wrapper, $dropzone)
      if ($relativeTimeFields.length) {
        RelativeTime.setRelativeTimes($relativeTimeFields)
      }
    },
  },
}

export default Measures
export { measureDropzoneConfig }
