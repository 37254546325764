import { Chart, ArcElement, DoughnutController } from "chart.js"
import Rails from "@rails/ujs"
import { blue, blueHover, green, greenHover } from "./common/colors"

const Licenses = {
  init(): void {
    const $wrapper = $("#wrapper")
    const $poolSection = $wrapper.find(".pool")
    const $chart = $poolSection.find(".chart")
    const $chartCenter = $chart.find(".center")
    const $chartLegend = $chart.find(".legend")
    const chartActiveLicensesNumber = parseInt(
      $chartLegend.find(".active .number").html()
    )
    const chartInactiveLicensesNumber = parseInt(
      $chartLegend.find(".inactive .number").html()
    )
    const $chartCanvas = $chart.find("canvas")
    const $assignSection = $wrapper.find(".assign")
    const $assignmentForm = $assignSection.find(".simple_form.assign")
    const $select = $assignSection.find("select")

    Chart.register(ArcElement, DoughnutController)

    new Chart($chartCanvas, {
      type: "doughnut",
      data: {
        labels: ["zugewiesen", "verfügbar"],
        datasets: [
          {
            data: [chartActiveLicensesNumber, chartInactiveLicensesNumber],
            backgroundColor: [blue, green],
            hoverBackgroundColor: [blueHover, greenHover],
          },
        ],
      },

      options: {
        animation: {
          easing: "easeOutQuint",
          animateRotate: true, // If true, will animate the rotation of the chart.
          animateScale: false, // If true, will animate scaling the Doughnut from the center.
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
          },
        },
        // The percentage of the chart that is cut out of the middle.
        cutout: "70%",
        responsive: true,
      },
    })

    $chartCenter.fadeIn("slow")
    $chartLegend.fadeIn("slow")

    $select.on("select2:select", () => {
      Rails.fire($assignmentForm[0], "submit")
    })
  },
}

function updateChart(activeLicenses: number, freeLicenses: number) {
  const chart = Chart.instances[0] // Only one on this page
  chart.data.datasets[0].data = [activeLicenses, freeLicenses]
  chart.update()
}

// @ts-ignore
window.Licenses ||= {}
// @ts-ignore
window.Licenses.updateChart = updateChart

export default Licenses
