import { autoJoin, toggleMark } from "prosemirror-commands"
import { redo, undo } from "prosemirror-history"
import { wrapInList, liftListItem, sinkListItem } from "prosemirror-schema-list"
import { EditorState } from "prosemirror-state"
import { MarkType, NodeType, MarkSpec } from "prosemirror-model"
import schema from "./schema"
import icons from "./icons"
import { MenuItemSpec } from "prosemirror-menu"

const markActive = (type: MarkType) => (state: EditorState) => {
  const { from, $from, to, empty } = state.selection

  return empty
    ? type.isInSet(state.storedMarks || $from.marks()) != null
    : state.doc.rangeHasMark(from, to, type)
}

const blockActive =
  (type: NodeType, attrs = {}) =>
  (state: MarkSpec) => {
    const { $from, to, node } = state.selection

    if (node) {
      return node.hasMarkup(type, attrs)
    }

    return to <= $from.end() && $from.parent.hasMarkup(type, attrs)
  }

// const canInsert = type => state => {
//   const { $from } = state.selection

//   for (let d = $from.depth; d >= 0; d--) {
//     const index = $from.index(d)

//     if ($from.node(d).canReplaceWith(index, index, type)) {
//       return true
//     }
//   }

//   return false
// }

// const promptForURL = () => {
//   let url = window && window.prompt("Enter the URL", "https://")

//   if (url && !/^https?:\/\//i.test(url)) {
//     url = "http://" + url
//   }

//   return url
// }

export type EditorMenu = {
  marks: Record<string, MenuItemSpec>
  blocks: Record<string, MenuItemSpec>
  history: Record<string, MenuItemSpec>
}

const Menu: EditorMenu = {
  marks: {
    strong: {
      title: "Fett",
      // @ts-ignore
      icon: icons.strong,
      active: markActive(schema.marks.strong),
      run: toggleMark(schema.marks.strong),
    },
    em: {
      title: "Kursiv",
      // @ts-ignore
      icon: icons.em,
      active: markActive(schema.marks.em),
      run: toggleMark(schema.marks.em),
    },
    // code: {
    //   title: 'Toggle code',
    //   icon: icons.code,
    //   active: markActive(schema.marks.code),
    //   run: toggleMark(schema.marks.code)
    // },
    // subscript: {
    //   title: 'Toggle subscript',
    //   icon: icons.subscript,
    //   active: markActive(schema.marks.subscript),
    //   run: toggleMark(schema.marks.subscript)
    // },
    // superscript: {
    //   title: 'Toggle superscript',
    //   icon: icons.superscript,
    //   active: markActive(schema.marks.superscript),
    //   run: toggleMark(schema.marks.superscript)
    // },
    underline: {
      title: "Unterstreichen",
      // @ts-ignore
      icon: icons.underline,
      active: markActive(schema.marks.underline),
      run: toggleMark(schema.marks.underline),
    },
    // strikethrough: {
    //   title: 'Toggle strikethrough',
    //   icon: icons.strikethrough,
    //   active: markActive(schema.marks.strikethrough),
    //   run: toggleMark(schema.marks.strikethrough)
    // },
    // link: {
    //   title: 'Add or remove link',
    //   icon: icons.link,
    //   active: markActive(schema.marks.link),
    //   enable: state => !state.selection.empty,
    //   run (state, dispatch) {
    //     if (markActive(schema.marks.link)(state)) {
    //       toggleMark(schema.marks.link)(state, dispatch)
    //       return true
    //     }

    //     const href = promptForURL()
    //     if (!href) return false

    //     toggleMark(schema.marks.link, { href })(state, dispatch)
    //     // view.focus()
    //   }
    // }
  },
  blocks: {
    // plain: {
    //   title: "Change to paragraph",
    //   icon: icons.paragraph,
    //   active: blockActive(schema.nodes.paragraph),
    //   enable: setBlockType(schema.nodes.paragraph),
    //   run: setBlockType(schema.nodes.paragraph)
    // },
    // code_block: {
    //   title: "Change to code block",
    //   icon: icons.code_block,
    //   active: blockActive(schema.nodes.code_block),
    //   enable: setBlockType(schema.nodes.code_block),
    //   run: setBlockType(schema.nodes.code_block)
    // },
    // h1: {
    //   title: "Change to heading level 1",
    //   icon: icons.heading,
    //   active: blockActive(schema.nodes.heading, { level: 1 }),
    //   enable: setBlockType(schema.nodes.heading, { level: 1 }),
    //   run: setBlockType(schema.nodes.heading, { level: 1 })
    // },
    // h2: {
    //   title: 'Change to heading level 2',
    //   icon: 'H2',
    //   active: blockActive(schema.nodes.heading, { level: 2 }),
    //   enable: setBlockType(schema.nodes.heading, { level: 2 }),
    //   run: setBlockType(schema.nodes.heading, { level: 2 })
    // },
    // blockquote: {
    //   title: "Wrap in block quote",
    //   icon: icons.blockquote,
    //   active: blockActive(schema.nodes.blockquote),
    //   enable: wrapIn(schema.nodes.blockquote),
    //   run: wrapIn(schema.nodes.blockquote)
    // },
    bullet_list: {
      title: "Aufzählung",
      // @ts-ignore
      icon: icons.bulletList,
      active: blockActive(schema.nodes.bullet_list),
      enable: wrapInList(schema.nodes.bullet_list),
      run: autoJoin(wrapInList(schema.nodes.bullet_list), ["bullet_list"]),
    },
    ordered_list: {
      title: "Nummerierte Aufzählung",
      // @ts-ignore
      icon: icons.orderedList,
      active: blockActive(schema.nodes.ordered_list),
      enable: wrapInList(schema.nodes.ordered_list),
      run: autoJoin(wrapInList(schema.nodes.ordered_list), ["ordered_list"]),
    },
    lift: {
      title: "In eine Ebene höher verschieben",
      // @ts-ignore
      icon: icons.lift,
      enable: liftListItem(schema.nodes.list_item),
      run: liftListItem(schema.nodes.list_item),
    },
    sink: {
      title: "In eine Ebene tiefer verschieben",
      // @ts-ignore
      icon: icons.sink,
      enable: sinkListItem(schema.nodes.list_item),
      run: sinkListItem(schema.nodes.list_item),
    },
    // join_up: {
    //   title: "Join with above block",
    //   icon: icons.join_up,
    //   enable: joinUp,
    //   run: joinUp
    // }
  },
  // insert: {
  //   image: {
  //     title: "Insert image",
  //     icon: icons.image,
  //     enable: canInsert(schema.nodes.image),
  //     run: (state, dispatch) => {
  //       const src = promptForURL()
  //       if (!src) return false

  //       const img = schema.nodes.image.createAndFill({ src })
  //       dispatch(state.tr.replaceSelectionWith(img))
  //     }
  //   },
  //   footnote: {
  //     title: "Insert footnote",
  //     icon: icons.footnote,
  //     enable: canInsert(schema.nodes.footnote),
  //     run: (state, dispatch) => {
  //       const footnote = schema.nodes.footnote.create()
  //       dispatch(state.tr.replaceSelectionWith(footnote))
  //     }
  //   },
  // hr: {
  //   title: 'Insert horizontal rule',
  //   icon: 'HR',
  //   enable: canInsert(schema.nodes.horizontal_rule),
  //   run: (state, dispatch) => {
  //     const hr = schema.nodes.horizontal_rule.create()
  //     dispatch(state.tr.replaceSelectionWith(hr))
  //   }
  // },
  // table: {
  //   title: "Insert table",
  //   icon: icons.table,
  //   enable: canInsert(schema.nodes.table),
  //   run: (state, dispatch) => {
  //     // const { from } = state.selection
  //     let rowCount = window && window.prompt("How many rows?", 2)
  //     let colCount = window && window.prompt("How many columns?", 2)

  //     const cells = []
  //     while (colCount--) {
  //       cells.push(schema.nodes.table_cell.createAndFill())
  //     }

  //     const rows = []
  //     while (rowCount--) {
  //       rows.push(schema.nodes.table_row.createAndFill(null, cells))
  //     }

  //     const table = schema.nodes.table.createAndFill(null, rows)
  //     dispatch(state.tr.replaceSelectionWith(table))

  //     // const tr = state.tr.replaceSelectionWith(table)
  //     // tr.setSelection(Selection.near(tr.doc.resolve(from)))
  //     // dispatch(tr.scrollIntoView())
  //     // view.focus()
  //   }
  // }
  // },
  history: {
    undo: {
      title: "Rückgängig",
      // @ts-ignore
      icon: icons.undo,
      enable: undo,
      run: undo,
    },
    redo: {
      title: "Wiederherstellen",
      // @ts-ignore
      icon: icons.redo,
      enable: redo,
      run: redo,
    },
  },
  // table: {
  // addColumnBefore: {
  //   title: 'Insert column before',
  //   icon: icons.after,
  //   active: addColumnBefore, // TODO: active -> select
  //   run: addColumnBefore
  // },
  // addColumnAfter: {
  //   title: 'Insert column before',
  //   icon: icons.before,
  //   active: addColumnAfter, // TODO: active -> select
  //   run: addColumnAfter
  // }
  // }
}

export default Menu
