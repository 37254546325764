import { nodes } from "prosemirror-schema-basic"
import { orderedList, bulletList, listItem } from "prosemirror-schema-list"

const oskarNodes = {
  doc: nodes.doc,
  paragraph: nodes.paragraph,
  text: nodes.text,
  hard_break: nodes.hard_break,
}

// We use ProseMirrors original schema, which is in snake_case. If we'd change
// these field names, the schema would change as well, making it incompatible.
const listNodes = {
  ordered_list: {
    ...orderedList,
    content: "list_item+",
    group: "block",
  },
  bullet_list: {
    ...bulletList,
    content: "list_item+",
    group: "block",
  },
  list_item: {
    ...listItem,
    content: "paragraph block*",
  },
}

export default { ...oskarNodes, ...listNodes }
