/*
  Code taken and adapter from https://stackoverflow.com/a/67676121/1928168.
*/

import { ArcElement, Plugin } from "chart.js"
import { sum } from "lodash-es"

const SPACE_BETWEEN_LABELS = 20

const getSuitableY = (
  y: number,
  yArray: number[] = [],
  direction: "left" | "right"
) => {
  let result = y
  yArray.forEach((existedY) => {
    if (
      existedY - SPACE_BETWEEN_LABELS < result &&
      existedY + SPACE_BETWEEN_LABELS > result
    ) {
      if (direction === "right") {
        result = existedY + SPACE_BETWEEN_LABELS
      } else {
        result = existedY - SPACE_BETWEEN_LABELS
      }
    }
  })

  return result
}

const plugin: Plugin<"doughnut"> = {
  id: "oskar-pie-data-labels",

  afterDraw: (chart) => {
    const ctx = chart.ctx
    ctx.save()
    ctx.font = "14px 'Lato', 'Verdana'"

    const leftLabelCoordinates: number[] = []
    const rightLabelCoordinates: number[] = []
    const chartCenterPoint = {
      x:
        (chart.chartArea.right - chart.chartArea.left) / 2 +
        chart.chartArea.left,
      y:
        (chart.chartArea.bottom - chart.chartArea.top) / 2 +
        chart.chartArea.top,
    }

    const data = chart.config.data.datasets[0].data as number[]
    const sumOfValues = sum(data)

    chart.config.data.labels?.forEach((label, i) => {
      const meta = chart.getDatasetMeta(0)
      const arc = meta.data[i] as unknown as ArcElement

      // Prepare data to draw
      // important point 1
      const centerPoint = arc.getCenterPoint()
      const angle = Math.atan2(
        centerPoint.y - chartCenterPoint.y,
        centerPoint.x - chartCenterPoint.x
      )

      // Important point 2.
      // This point overlapped with existed points so we will reduce y by
      // SPACE_BETWEEN_LABELS if it's on the right or add by
      // SPACE_BETWEEN_LABELS if it's on the left.
      const point2X =
        chartCenterPoint.x +
        Math.cos(angle) * (arc.getProps(["outerRadius"]).outerRadius + 15)
      let point2Y =
        chartCenterPoint.y +
        Math.sin(angle) * (arc.getProps(["outerRadius"]).outerRadius + 15)

      let suitableY
      if (point2X < chartCenterPoint.x) {
        // on the left
        suitableY = getSuitableY(point2Y, leftLabelCoordinates, "left")
      } else {
        // on the right
        suitableY = getSuitableY(point2Y, rightLabelCoordinates, "right")
      }

      point2Y = suitableY
      const edgePointX = point2X < chartCenterPoint.x ? 10 : chart.width - 10

      if (point2X < chartCenterPoint.x) {
        leftLabelCoordinates.push(point2Y)
      } else {
        rightLabelCoordinates.push(point2Y)
      }

      //DRAW CODE
      // first line: connect between arc's center point and outside point
      ctx.strokeStyle = "#6d6c8c"
      ctx.beginPath()
      ctx.moveTo(centerPoint.x, centerPoint.y)
      ctx.lineTo(point2X, point2Y)
      ctx.stroke()

      // second line: connect between outside point and chart's edge
      ctx.beginPath()
      ctx.moveTo(point2X, point2Y)
      ctx.lineTo(edgePointX, point2Y)
      ctx.stroke()

      //fill custom label
      const labelAlignStyle = edgePointX < chartCenterPoint.x ? "left" : "right"
      const labelX = edgePointX
      const labelY = point2Y
      const labelValue = `${label} – ${Number(
        data[i] / sumOfValues
      ).toLocaleString(undefined, {
        style: "percent",
        minimumFractionDigits: 0,
      })}` as string

      ctx.textAlign = labelAlignStyle
      ctx.textBaseline = "bottom"
      ctx.fillStyle = "#6d6c8c"
      ctx.fillText(labelValue, labelX, labelY)
    })
    ctx.restore()
  },
}

export default plugin
