"use strict"

import { _adapters } from "chart.js"
import dayjs from "dayjs"
import LocalizedFormat from "dayjs/plugin/localizedFormat"
dayjs.extend(LocalizedFormat)

const FORMATS = {
  datetime: "lll",
  millisecond: "HH:mm:ss.SSS",
  second: "LTS",
  minute: "LT",
  hour: "HH",
  day: "MMM D",
  week: "ll",
  month: "MMM YYYY",
  quarter: "[Q]Q - YYYY",
  year: "YYYY",
}

_adapters._date.override({
  _id: "dayjs", // DEBUG ONLY

  formats() {
    return FORMATS
  },

  parse(value, format) {
    if (typeof value === "string" && typeof format === "string") {
      value = dayjs(value, format)
    } else if (!(value instanceof dayjs)) {
      value = dayjs(value)
    }
    return value.isValid() ? value.valueOf() : null
  },

  format(time, format) {
    return dayjs(time).format(format)
  },

  add(time, amount, unit) {
    return dayjs(time).add(amount, unit).valueOf()
  },

  diff(max, min, unit) {
    return dayjs(max).diff(dayjs(min), unit)
  },

  startOf(time, unit, weekday) {
    time = dayjs(time)
    if (unit === "isoWeek") {
      return time.isoWeekday(weekday).valueOf()
    }
    return time.startOf(unit).valueOf()
  },

  endOf(time, unit) {
    return dayjs(time).endOf(unit).valueOf()
  },

  // DEPRECATIONS

  /**
   * Provided for backward compatibility with scale.getValueForPixel().
   * @deprecated since version 2.8.0
   * @todo remove at version 3
   * @private
   */
  _create(time) {
    return dayjs(time)
  },
})
