import Dropzones from "./dropzones"

export enum Mode {
  locked = 0,
  unlocked = 1,
}

const Screenlock = {
  scrollPosition: {
    y: 0,
  },

  scrollDistance: {
    reset(): void {
      const $wrapper = $("#wrapper")

      $wrapper.css({ height: "auto", overflowY: "auto" })
    },

    /**
     * Limit the vertical scrolling distance to the dialog height incl. offset
     */
    adjust(): void {
      const $screenlock = $("#screenlock")
      const $wrapper = $("#wrapper")
      const $dialogWrapper = $("#dialog-wrapper")
      const $dialog = $screenlock.find("#dialog")
      const currentWindowHeight = $(window).height()

      if ($dialog.length) {
        Screenlock.scrollDistance.reset()

        $wrapper.css({
          height: currentWindowHeight + "px",
          overflowY: "hidden",
        })

        $dialogWrapper.css({
          height: currentWindowHeight + "px",
        })
      }
    },
  },

  hide(): void {
    const $screenlock = $("#screenlock")
    const $dialog = $screenlock.find("#dialog")

    if ($dialog.length) {
      $dialog.removeClass("show").addClass("hide")
    }

    Screenlock.scrollDistance.reset()

    $screenlock.fadeOut("fast", function () {
      $(this).empty()
    })

    $(window).scrollTop(Screenlock.scrollPosition.y)
  },

  resize(): void {
    const $screenlock = $("#screenlock")
    const windowHeight = $(window).height()
    const windowWidth = $(window).width()

    // @ts-ignore
    $screenlock.css({ height: windowHeight, width: windowWidth })
    Screenlock.scrollDistance.adjust()
  },

  show(mode: Mode): void {
    const $screenlock = $("#screenlock")
    const $dropzone = $screenlock.find(".dropzone")

    // @ts-ignore
    Screenlock.scrollPosition.y = $(window).scrollTop()

    Screenlock.resize()

    $screenlock
      .css({ display: "flex" })
      .hide()
      .fadeIn("fast", () => {
        Screenlock.scrollDistance.adjust()

        if ($dropzone.length) {
          Dropzones.addDropzoneToDialog($screenlock)
        }
      })

    if (mode === Mode.unlocked) {
      $screenlock.on("click.oskar", function (event) {
        if ($(this).is(event.target)) {
          Screenlock.hide()
        }
      })
    }
  },
}

export default Screenlock
