import { Node } from "prosemirror-model"
import schema from "../schema"
import initialContent from "../initialContent"

function isEditorBlank(editorJsonString: string): boolean {
  const editorJson = JSON.parse(editorJsonString)

  if ($.isEmptyObject(editorJson)) return true

  const doc = Node.fromJSON(schema, editorJson.doc)
  return doc.textContent.trim() === ""
}

function editorContentChanged(
  originalJsonString: string,
  newJsonString: string
): boolean {
  const originalJson =
    originalJsonString === "" ||
    // Android sends `{}` for empty editors, but that's not a valid structure
    // for prosemirror.
    originalJsonString === "{}" ||
    originalJsonString == null
      ? initialContent
      : JSON.parse(originalJsonString)
  const newJson = JSON.parse(newJsonString)
  const originalDocument = Node.fromJSON(schema, originalJson.doc)
  const newDocument = Node.fromJSON(schema, newJson.doc)

  return !originalDocument.eq(newDocument)
}

export { isEditorBlank, editorContentChanged }
