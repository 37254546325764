import { MarkSpec } from "prosemirror-model"
import { marks } from "prosemirror-schema-basic"

const underline: MarkSpec = {
  parseDOM: [{ tag: "u" }, { style: "text-decoration=underline" }],
  toDOM: () => [
    "u",
    {
      style: "text-decoration:underline",
    },
  ],
}

export default {
  em: marks.em,
  strong: marks.strong,
  underline,
}
