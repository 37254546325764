import { createRoot } from "react-dom/client"
import { EditorView } from "prosemirror-view"
import { EditorState } from "prosemirror-state"
import Editor from "./Editor"
import MenuBar from "./MenuBar"
import menu from "./menu"
import schema from "./schema"

const OSKAREditor = {
  init(content: EditorState, canEdit = true): void {
    // This is where the apps get their JSON from
    const container = document.getElementById("text-editor")
    // @ts-ignore
    const root = createRoot(container)

    root.render(
      <Editor
        initialValue={schema.nodeFromJSON(content.doc)}
        canEdit={canEdit}
        render={({ editor, view }: { editor: Editor; view: EditorView }) => (
          <>
            {canEdit && <MenuBar menu={menu} view={view} />}
            {editor}
            {
              <input
                type="hidden"
                className="editor-json"
                value={JSON.stringify(view.state)}
              ></input>
            }
          </>
        )}
      />
    )
  },
}

export default OSKAREditor
